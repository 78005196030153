import React from "react";
import "../App.css";
import { Row, Col, Container } from "react-bootstrap";
import { IoLocationOutline } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";

const Footer = () => {
  return (
    <>
      <section className="footer-main-section mt-5">
        <div className="main-footer-content">
          <div className="footer-content">
            <div className="footer-links-section">
              <Container>
                <Row>
                  <Col md={3}>
                    <div
                      className="popular-corses-links"
                      style={{
                        overflow: "hidden",
                        paddingTop: "100px",
                      }}
                    >
                      <h2
                        style={{
                          color: "#fff",
                          overflow: "hidden",
                          maxWidth: "100%",
                        }}
                      >
                        Popular Courses
                      </h2>
                    </div>
                    <div className="courses-links">
                      <ul>
                        <li>Fashion Designing Courses</li>
                        <li>Interior Designing Courses</li>
                        <li>Graphics Designing Courses</li>
                        <li>Jewellery Designing Courses</li>
                        <li>Animation Designing Courses</li>
                        <li>Textile Designing Courses</li>
                      </ul>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div
                      className="popular-corses-links"
                      style={{
                        overflow: "hidden",
                        paddingTop: "100px",
                      }}
                    >
                      <h2
                        style={{
                          color: "#fff",
                          overflow: "hidden",
                          maxWidth: "100%",
                        }}
                      >
                        Top Courses
                      </h2>
                    </div>
                    <div className="courses-links">
                      <ul>
                        <li>MBA - Interior Design</li>
                        <li>MBA - Fashion Design</li>
                        <li>MSC - Textile Design</li>
                        <li>MSC - Graphic Design</li>
                        <li>MSC - Interior Design</li>
                        <li>MSC - Fashion Design</li>
                      </ul>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div
                      className="popular-corses-links"
                      style={{
                        overflow: "hidden",
                        paddingTop: "100px",
                      }}
                    >
                      <h2
                        style={{
                          color: "#fff",
                          overflow: "hidden",
                          maxWidth: "100%",
                        }}
                      >
                        Quick Job Courses
                      </h2>
                    </div>
                    <div className="courses-links">
                      <ul>
                        <li>MDES Interior Designing</li>
                        <li>MDES Jewellery Designing</li>
                        <li>MDES Animation Designing</li>
                        <li>MDES Fashion Designing</li>
                        <li>MDES Graphic Designing</li>
                        <li>MDES Textile Designing</li>
                      </ul>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div
                      className="popular-corses-links"
                      style={{
                        overflow: "hidden",
                        paddingTop: "100px",
                      }}
                    >
                      <h2
                        style={{
                          color: "#fff",
                          overflow: "hidden",
                          maxWidth: "100%",
                        }}
                      >
                        Contact Us
                      </h2>
                    </div>
                    <div className="courses-links-cn">
                      <ul>
                        <li>
                          <div className="contact-us">
                            <IoLocationOutline
                              style={{ fontSize: "65px", paddingRight: "15px" }}
                            />
                            <div>
                              <span>
                             1st & 2nd Floor<br/>
                             PLOT NO-IS-116/1
                                <br />
                                Land mark, BLOCK HOSTEL, SECTOR - 1,
                                Shastri Nagar, Meerut – 250004
                              </span>
                            </div>
                          </div>
                        </li>
                       
                        <li   style={{ maxWidth: "100%", overflow: "hidden" }}>
                          <div
                            className="contact-us"
                            style={{ maxWidth: "100%", overflow: "hidden" }}
                          >
                            <div className="d-flex">
                            <FiPhoneCall />
                              <span
                                style={{
                                  display: "block",
                                  maxWidth: "100%",
                                  overflow: "hidden",
                                  paddingLeft:'15px'
                                }}
                              >
                                +91 9410-501-502
                              </span>
                              
                              </div>
                          </div>
                        </li>
                        <li   style={{ maxWidth: "100%", overflow: "hidden" }}>
                          <div
                            className="contact-us"
                            style={{ maxWidth: "100%", overflow: "hidden" }}
                          >
                            <div className="d-flex">
                            <FiPhoneCall />
                              <span
                                style={{
                                  display: "block",
                                  maxWidth: "100%",
                                  overflow: "hidden",
                                  paddingLeft:'15px'
                                }}
                              >
                                +91 7874155490
                              </span>
                              
                              </div>
                          </div>
                        </li>
                        <li   style={{ maxWidth: "100%", overflow: "hidden" }}>
                          <div
                            className="contact-us"
                            style={{ maxWidth: "100%", overflow: "hidden" }}
                          >
                            <div className="d-flex">
                            <FiPhoneCall />
                              <span
                                style={{
                                  display: "block",
                                  maxWidth: "100%",
                                  overflow: "hidden",
                                  paddingLeft:'15px'
                                }}
                              >
                                +91 9818070864
                              </span>
                              
                              </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        {/* <div className="copyright">
          <Container>
            <Row>
              <Col md={12}>
          <div className="inner-text py-5">
            <p style={{color:'#fff'}}>© copyright 2024 INSD | Web Development By Midaslogix</p>
          </div>
          </Col>
          </Row>
          </Container>
        </div> */}
      </section>
    </>
  );
};

export default Footer;
