import React, { useState } from "react";
import "../App.css";
import fashionDesign from "../assets/images/fashiondesign.jpg";
import parallex from "../assets/images/parallex.webp";
import { Row, Container, Col, Form } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import ContactUsModal from "./modals/ContactUsModal";
import { useNavigate } from "react-router-dom";


const Courses = () => {
  const [key, setKey] = useState("Year One");
  const [key1, setKey1] = useState("Year One");
  const [key2, setKey2] = useState("Year One");
  const [key3, setKey3] = useState("Year One");
  const [key4, setKey4] = useState("6 Months");
  const [key5, setKey5] = useState("6 Months");
  const [key6, setKey6] = useState("6 Months");
  const [contactModal, setContactModal] = useState(false);
  const [nameModal, setNameModal] = useState('');
  const navigate = useNavigate();
  const handleContact = (e) => {
    console.log('ee', e.target.innerText)
    setNameModal(e.target.innerText);
    setContactModal(true);
  }
  const toggleModal = (isOpen) => {
    setContactModal(isOpen);
  };
  const handleAdmissionForm = () => {
    navigate('/admission-form');
  }

  return (
    <>
      <div
        className="main-courses-div"
        style={{ backgroundImage: `url(${parallex})`, overflow: "hidden" }}
      >
        <div className="main-course-img">
          <img src={fashionDesign} style={{ width: "100%", height: "100%" }} />
        </div>
        <div
          className="career-paths-main mt-5"
          style={{ maxWidth: "100%", overflow: "hidden" }}
        >
          <Container>
            <Row>
              <h1 style={{ maxWidth: "100%", overflow: "hidden" }}>
                Career Paths:
              </h1>
              <Col md={4}>
                <div className="career-path-div">
                  <div className="career-paths mt-4">
                    <ul>
                      <li>Fashion Designer</li>
                      <li>Retail Buyer</li>
                      <li>Retail Manager</li>
                      <li>Fashion Stylist</li>
                      <li>Textile Designer</li>
                      <li>Personal Shopper</li>
                      <li>Designer/Assistant Designer</li>
                      <li>Pattern Designer</li>
                      <li>Fashion Illustrator</li>
                    </ul>
                  </div>
                </div>
              </Col>

              {/* second column starts here */}

              <Col md={4}>
                <div className="career-path-div">
                  <div className="career-paths mt-4">
                    <ul>
                      <li>Fashion Entrepreneur</li>
                      <li>Costume designer</li>
                      <li>Fashion consultant</li>
                      <li>Personal stylist</li>
                      <li>Production pattern maker</li>
                      <li>Fashion coordinator</li>
                      <li>Trend forecaster</li>
                      <li>Fashion Blogger</li>
                      <li>Visual Merchandiser</li>
                    </ul>
                  </div>
                </div>
              </Col>
              {/* Third column starts here */}
              <Col md={4}>
                <div className="career-path-div">
                  <div className="career-paths mt-4">
                    <ul>
                      <li>Brand Consultant</li>
                      <li>Accessory Designer</li>
                      <li>Assistant Buyer</li>
                      <li>Fashion Buyer</li>
                      <li>Fashion Merchandiser</li>
                      <li>Fashion House Agent</li>
                      <li>Production Quality Analyst</li>
                      <li>Fashion show coordinator</li>
                      <li>Fashion Show organizer</li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div
        className="btns"
        id="contact-sticky-button"
        style={{ maxWidth: "100%", overflow: "hidden" }}
        onClick={(e)=>handleContact(e)}
      >
       
       
         <span style={{cursor:'pointer', color:'#fff', padding:'5px'}} className="pum-trigger-admission">
         Contact Us
        </span>
      </div>
      <div
        className="btns"
        id="admission-sticky-button"
        style={{ overflow: "hidden", maxWidth: "100%", cursor:'pointer' }}
        onClick={handleAdmissionForm}
      >
        <span className="pum-trigger-admission">
          Admission Form
        </span>
      </div>
      <div
        className="btns"
        id="getfreecoun-sticky-button"
        style={{ overflow: "hidden", maxWidth: "100%" }}
        onClick={(e)=>handleContact(e)}
      >
        <span className="pum-trigger-getfreecoun" style={{cursor:'pointer'}}>
          Get Free Career Counseling
        </span>
      </div>
        <div className="essential-duties">
          <Container>
            <Row>
              <div
                className="essential-text mt-5"
                style={{ maxWidth: "100%", overflow: "hidden" }}
              >
                <h2
                  className="text-center"
                  style={{ maxWidth: "100%", overflow: "hidden" }}
                >
                  Essential Duties and Responsibilities of a Fashion Designer
                </h2>
                <div className="d-flex f-design">
                  <Col md={6}>
                    <div className="essential-text-data mt-4 mx-5">
                      <ul style={{ color: "#333333", fontSize: "18px" }}>
                        <li>
                          Researching current fashion trends and determining
                          what consumers will like in the future
                        </li>
                        <li>
                          Collaborating with the design team to develop ideas
                          for new products based on fashion forecast
                        </li>
                        <li>
                          Designing illustrations for new products depending on
                          the current demand and brand requirement
                        </li>
                        <li>Creating clothing patterns for mass production</li>
                      </ul>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div
                      className="essential-text-data mt-4 mx-5"
                      style={{ color: "#333333", fontSize: "18px" }}
                    >
                      <ul>
                        <li>
                          Testing and deciding on fabrics, colors, patterns and
                          textures for each design and material research
                        </li>
                        <li>
                          Maintaining relationships with vendors, suppliers,
                          models, photographers and influencers
                        </li>
                        <li>
                          Developing CAD sketches to present to stakeholders and
                          making mood boards for presentation
                        </li>
                        <li>
                          Customizing clothes according client specifications
                          eg. wedding wear
                        </li>
                      </ul>
                    </div>
                  </Col>
                </div>
              </div>
            </Row>
          </Container>
        </div>
        <div>
            <Row>
                <Container>
                <section className="contact-us-form mt-5 mb-5">
        <Container>
          <div className="booking-form">
            <Form  action="https://formspree.io/f/mvoevrna"
  method="POST">
              <div className="form-group">
                <div className="form-checkbox">
                  <label>
                    <span className="text-center">Book your courses..</span>
                  </label>
                </div>
                <Row className="mt-2">
                  <Col md={6}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        Name
                      </span>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        E-MAIL ADDRESS
                      </span>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={6}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        PHONE NUMBER
                      </span>
                      <input
                        className="form-control"
                        type="text"
                        name="contact number"
                        placeholder="Your contact number"
                        required
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        SELECT COURSE
                      </span>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        name="courses"
                        required
                      >
                       <option>Open this select menu</option>
                        <option value="Fashion Design">Fashion Design</option>
                        <option value="Interior Design">Interior Design</option>
                        <option value="Graphic Design">Graphic Design</option>
                        <option value="Animation Design">Animation Design</option>
                        <option value="Luxury Management">Luxury Management</option>
                        <option value="Jewellary Design">Jewellary Design</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-btn">
                      <button className="submit-btn">Book Now</button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Container>
      </section>
                </Container>
            </Row>
        </div>
        <div className="fashion-designing-courses mt-5">
          <Container>
            <Row>
              <div
                className="fashion-designing-text"
                style={{
                  maxWidth: "100%",
                  overflow: "hidden",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                }}
              >
                <h1 style={{ maxWidth: "100%", overflow: "hidden" }}>
                  Fashion Designing Courses in Meerut : UG / PG Courses
                </h1>
                <div className="fas-sub-heading mt-5">
                  <h3>
                    UG: Bachelors Programmes – 4 Years Specialization Course
                    (Eligibility : 10+2)
                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => setKey(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="Year One"
                        title="Year One"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">Semester – I</th>
                              <th className="py-3 px-4">Semester – II</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Foundation Art
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Design foundation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction to pattern making
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction to textile
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Fashion illustration
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction to PMT part 2
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                History of costumes
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Basic embroidery
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Sewing technology (Practical 1)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                CAD (Practical 1)
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Fashion project (Practical 2)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                GMT (Practical 2)
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Color theory
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Grooming & communication skills
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Kid’s anatomy
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Basic of fashion
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              ></td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Illustration – female (Theme based)
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Two" title="Year Two">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">Semester – III</th>
                              <th className="py-3 px-4">Semester – IV</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Adv. Fashion illustration
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Apparel design
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction to draping
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Theory of dyeing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Pattern making techniques part 1
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                PMT & GMT
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                History of Indian costumes
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Traditional embroidery
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Inspiration on project (Practical 1)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                CAD (Practical 1)
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Garment project (Practical 2)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Dyeing project (Practical 2)
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Design process
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Accessories design
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Boutique management
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Fabric manipulation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Fashion show training
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              ></td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Three" title="Year Three">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">Semester – V</th>
                              <th className="py-3 px-4">Semester – VI</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Fashion analysis
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Advance fashion illustration 2
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Quality control
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Surface ornamentation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Int. to printing techniques
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Fashion merchandising
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                History of western world fashion
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Industry project and viva
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Fashion forecasting (Practical 1)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Final presentation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Men’s wear project (Practical 2)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Portfolio development
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Dyeing project
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Advance accessories design
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Design concept
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Retail marketing & visual merchandising
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Advance styling
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Adobe illustrator
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Four" title="Year Four">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">Semester – VII</th>
                              <th className="py-3 px-4">Semester – VIII</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Advance illustration
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Design development
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Commercial pattern making apparel
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Commercial pattern making 2
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Apparel design
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Entrepreneurship development
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Art appreciation{" "}
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Apparel design 2
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Garment construction (Practical 1)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Advance draping
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Design folio (Practical 2)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Industry internship
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Production management
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Market survey & swatch board creation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Documentation
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Vendor development
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              ></td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Advance adobe illustrator
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                    </Tabs>
                  </div>
                </div>

                {/* second tab */}

                <div className="fas-sub-heading mt-5">
                  <h3>
                    UG: Bachelors Programmes – 3 Years Specialization Course
                    (Eligibility : 10+2)
                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key1}
                      onSelect={(k) => setKey1(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="Year One"
                        title="Year One"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">Semester – I</th>
                              <th className="py-3 px-4">Semester – II</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Foundation Art
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Design foundation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction to pattern making
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction to textile
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Fashion illustration
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction to PMT part 2
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                History of costumes
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Basic embroidery
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Sewing technology (Practical 1)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                CAD (Practical 1)
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Fashion project (Practical 2)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                GMT (Practical 2)
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Photoshop (Practical 3)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Grooming & communication skills
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Color theory
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Basic of fashion
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Kid’s anatomy
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Illustration – female (Theme based)
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Two" title="Year Two">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">Semester – III</th>
                              <th className="py-3 px-4">Semester – IV</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Adv. Fashion illustration
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Apparel design
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction to draping
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Theory of dyeing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Pattern making techniques part 1
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                PMT & GMT
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                History of Indian costumes
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Traditional embroidery
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Inspiration on project (Practical 1)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                CAD (Practical 1)
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Garment project (Practical 2)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Dyeing project (Practical 2)
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Styling
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Accessories design
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Design process
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Fabric manipulation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Boutique management
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              ></td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Fashion show training
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              ></td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Three" title="Year Three">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">Semester – V</th>
                              <th className="py-3 px-4">Semester – VI</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Fashion analysis
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Advance fashion illustration 2
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Quality control
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Surface ornamentation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Int. to printing techniques
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Fashion merchandising
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                History of western world fashion
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Industry project and viva
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Fashion forecasting (Practical 1)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Final presentation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Men’s wear project (Practical 2)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Portfolio development
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Dyeing project
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Advance accessories design
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Design concept
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Retail marketing & visual merchandising
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Advance styling
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Adobe illustrator
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                    </Tabs>
                  </div>
                </div>



                {/* third Tab */}



                <div className="fas-sub-heading mt-5">
                  <h3>
                   
PG Course – 2 Years (Eligibility: Graduate)
                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key2}
                      onSelect={(k) => setKey2(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="Year One"
                        title="Year One"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">1st Semester – 22 Credits</th>
                              <th className="py-3 px-4">2nd Semester – 24 Credits</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Basic of designing
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Fashion Foundation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Pattern making techniques
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Textile theory
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Fashion illustration
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Pattern developing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Management process & behavior
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Entrepreneurship development
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                History of costumes	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                CAD (Practical 1)
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Product development (Practical)	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                GMT (Practical 2)
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Practical – Photoshop
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Grooming & communication skills
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Color theory
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Basic of fashion
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Inspiration project
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Study of apparel marketing
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Two" title="Year Two">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">3rd Semester – 24 Credits</th>
                              <th className="py-3 px-4">4th Semester – 22 Credits</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Advance fashion illustration Adv. Fashion illustration
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Quality control technology
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Advance draping	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Dyeing & Printing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Surface ornamentation
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Fashion merchandising - A retailing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Industry project and viva (Practical 1)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Traditional textile and state embroidery
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                PMT GMT (Practical 2)
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                CAD
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Industry documentation	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               PMT GMT project
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Textile clothing & care

                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Portfolio development

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Design concept	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Industrial internship

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >Market survey & swatch book creation</td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >Vendor development
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                    </Tabs>
                  </div>
                </div>


{/* fourth Tab */}

        <div className="fas-sub-heading mt-5">
                  <h3>
                   
                  1 Year Advance Course (Eligibility : 10+2)
                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key3}
                      onSelect={(k) => setKey3(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="Year One"
                        title="Year One"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">1st Semester – 20 credits</th>
                              <th className="py-3 px-4">2nd Semester – 20 credits</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                            Elements of design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Fashion illustration
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Fashion Industrial Language	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
Different body drape elements a function of clothing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Basic principle of fashion	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Introduction to textile theory

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Stitching methods & application	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                History of costumes

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Draping – 1	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Introduction to pattern making

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Practical
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Practical
                              </td>
                            </tr>
                           
                           
                          </tbody>
                        </Table>
                      </Tab>
                     
                    </Tabs>
                  </div>
                </div>

{/* fifth tab */}
<div className="fas-sub-heading mt-5">
                  <h3>
                   
                  Diploma In Fashion Design & Technology

                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key4}
                      onSelect={(k) => setKey4(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="6 Months"
                        title="6 Months"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">Semester – I</th>
                              <th className="py-3 px-4">Semester – II</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                           Fundamental designing	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Fashion concept

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Introduction to pattern making	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                    Introduction to textile
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Fashion illustration	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                             Introduction to PMT & GMT


                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                             History of costumes	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Basic embroidery


                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Stitching Method and application (Practical 1)

                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Draping (Practical 1)


                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                            Dummy draping presentation (Practical 2)	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Portfolio making (Practical 2)

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                           Color theory
	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                             Basic CAD – Photoshop


                              </td>
                            </tr>

                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                           Basic sketching	
	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                           Inspiration & research project



                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                         Kids anatomy
	
	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                          



                              </td>
                            </tr>
                           
                          </tbody>
                        </Table>
                      </Tab>
                     
                    </Tabs>
                  </div>
                </div>

                {/* sixth tab */}



                <div className="fas-sub-heading mt-5">
                  <h3>
                   
                  Advance Diploma In Fashion Design


                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key5}
                      onSelect={(k) => setKey5(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="6 Months"
                        title="6 Months"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">Semester – I</th>
                              <th className="py-3 px-4">Semester – II</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                          Adv fashion illustration		
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                             Apparel design


                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                             Fashion merchandising		
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                   Dyeing & printing

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                             Advance PMT GMT part 1		
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                            Advance PMT GMT part 2



                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                           History of Indian costumes		
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Traditional embroidery


                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                             Inspiration on Project (Practical 1)


                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                CAD (Practical 1)



                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                          GMT project (Practical 2)		
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                             Fashion visits and viva project (Practical 2)


                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                             Portfolio development



                              </td>
                            </tr>

                           
                           
                          </tbody>
                        </Table>
                      </Tab>
                     
                    </Tabs>
                  </div>
                </div>

{/* seventh tab */}
<div className="fas-sub-heading mt-5">
                  <h3>
                   
                  Fashion Designing Certification Courses In Ahmedabad- 6 Months (Eligibility: 10th)



                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key6}
                      onSelect={(k) => setKey6(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="6 Months"
                        title="6 Months"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">Foundation Art</th>
                              <th className="py-3 px-4">Elements of principle of fashion design</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                         Introduction of Pattern making & GMT			
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                            Fashion & Apparel design fundamental


                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                            Practical – 1 Portfolio Making		
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                  Practical – 2 Pattern Making & Grading


                              </td>
                            </tr>
                           
                           
                          </tbody>
                        </Table>
                      </Tab>
                     
                    </Tabs>
                  </div>
                </div>

              </div>
            </Row>
          </Container>
        </div>
      </div>
    <ContactUsModal contactModal={contactModal} nameModal={nameModal} toggleModal={toggleModal}/>
    </>
  );
};

export default Courses;
