import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({children}) => {
   
    return (
        <>
            <Header />
            <main>{children}</main>
            <Footer id="footer"/>
            </>
    )
} 

export default Layout;