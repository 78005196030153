import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Main from './components/Main';
import Layout from './components/Layout';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdmissionForm from './components/AdmissionForm';
import Courses from './components/Courses';
import GraphicDesigning from './components/GraphicDesigning';
import InteriorDesigning from './components/InteriorDesign';
import Animation from './components/Animation';
import InternationalExposure from './components/InternationalExposure';

function App() {
  return (
    <>
   
   <Router>
      <Layout>
        <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path = "/admission-form" element={<AdmissionForm/>}/>
        <Route exact path = "/fashion-design" element={<Courses/>}/>
        <Route exact path = "/graphic-design" element={<GraphicDesigning/>}/>
        <Route exact path = "/interior-design" element={<InteriorDesigning/>}/>
        <Route exact path = "/animation-design" element={<Animation/>}/>
        <Route exact path = "/placement" element={<InternationalExposure/>}/>





        {/* Add more routes here as needed */}
        </Routes>
      </Layout>
    </Router>
    </>
    
  );
}

export default App;
