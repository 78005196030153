import React from "react";
import { Col, Container, Row, Card, CardBody } from "react-bootstrap";
import "../App.css";
import Fade from "react-reveal/Fade";
import fs1 from "../assets/images/fs1.jpg";
import fs2 from "../assets/images/fs2.png";
import fs3 from "../assets/images/fs3.png";
import fs4 from "../assets/images/fs4.png";
import fs5 from "../assets/images/fs5.jpg";
import ps1 from "../assets/images/Placement1.jpg";
import ps2 from "../assets/images/placement2.jpg";
import ps3 from "../assets/images/placement3.jpg";
import ps4 from "../assets/images/placement4.jpg";

import loader from "../assets/images/loader1.png";

import YouTube from "react-youtube";

const InternationalExposure = () => {
  const videoId = "P0DX6nYbM5k";
  const opts = {
    height: "590",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <>
      {/* <Fade> */}
      <section>
        <div>
          <YouTube videoId={videoId} opts={opts} />
        </div>
      </section>
      {/* </Fade> */}
      <Fade>
        <section className="mt-4">
          <Fade>
            <div
              className="placement-sec"
              style={{
                backgroundColor: "#000",
                maxWidth: "100%",
                overflow: "hidden",
              }}
            >
              <div
                className="placement-content"
                style={{ maxWidth: "100%", overflow: "hidden" }}
              >
                <Container className="py-3">
                  <Row>
                    <Col md={3}>
                      <div
                        className="main-place py-5"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <div
                          style={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            padding: "80px 0",
                          }}
                        >
                          <h3
                            className="placement-heading text-center"
                            style={{
                              color: "#fff",
                              maxWidth: "100%",
                              overflow: "hidden",
                            }}
                          >
                            15000+
                          </h3>
                          <p
                            className="text-center"
                            style={{
                              color: "#fff",
                              fontSize: "30px",
                              fontWeight: "normal",
                            }}
                          >
                            STUDENTS GRADUATED
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div
                        className="main-place py-5"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <div
                          style={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            padding: "80px 0",
                          }}
                        >
                          <h3
                            className="placement-heading text-center"
                            style={{
                              color: "#fff",
                              maxWidth: "100%",
                              overflow: "hidden",
                            }}
                          >
                            120+
                          </h3>
                          <p
                            className="text-center"
                            style={{
                              color: "#fff",
                              fontSize: "30px",
                              fontWeight: "normal",
                            }}
                          >
                            FACULTY PRESENCE
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div
                        className="main-place py-5"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <div
                          style={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            padding: "80px 0",
                          }}
                        >
                          <h3
                            className="placement-heading text-center"
                            style={{
                              color: "#fff",
                              maxWidth: "100%",
                              overflow: "hidden",
                            }}
                          >
                            7
                          </h3>
                          <p
                            className="text-center"
                            style={{
                              color: "#fff",
                              fontSize: "30px",
                              fontWeight: "normal",
                            }}
                          >
                            INTERNATIONAL CAMPUSES
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div
                        className="main-place py-5"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <div
                          style={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            padding: "80px 0",
                          }}
                        >
                          <h3
                            className="placement-heading text-center"
                            style={{
                              color: "#fff",
                              maxWidth: "100%",
                              overflow: "hidden",
                            }}
                          >
                            50+
                          </h3>
                          <p
                            className="text-center"
                            style={{
                              color: "#fff",
                              fontSize: "30px",
                              fontWeight: "normal",
                            }}
                          >
                            NATIONAL CAMPUSES
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div
                        className="main-place py-5"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <div
                          style={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            padding: "80px 0",
                          }}
                        >
                          <h3
                            className="placement-heading text-center"
                            style={{
                              color: "#fff",
                              maxWidth: "100%",
                              overflow: "hidden",
                            }}
                          >
                            100+
                          </h3>
                          <p
                            className="text-center"
                            style={{
                              color: "#fff",
                              fontSize: "30px",
                              fontWeight: "normal",
                            }}
                          >
                            BRAND PARTNERS
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Fade>
        </section>
      </Fade>
      <Fade>
        <section className="featured-section mt-5">
          <Fade>
            <Container fluid>
              <h1
                className="px-2"
                style={{
                  fontWeight: "bolder",
                  maxWidth: "100%",
                  overflow: "hidden",
                }}
              >
                Featured in
              </h1>
              <div className="fetures-img mt-5">
                <div className="mt-5">
                  {/* <Row>
                                <Col md={3}>
                                    <div className="d-flex justify-content-center align-items-center">

                                    <img src={`${fs1}`} className="img-fluid"/>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className="d-flex justify-content-center">
                                    <img src={`${fs2}`} className="img-fluid"/>

                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div>
                                    <img src={`${fs3}`} className="img-fluid"/>

                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div>

                                    <img src={`${fs4}`} className="img-fluid"/>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div>
                                    <img src={`${fs5}`} className="img-fluid"/>

                                    </div>
                                </Col>
                          </Row> */}
                  <Row>
                    <Col
                      md={3}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <img src={`${fs1}`} className="img-fluid" />
                    </Col>
                    <Col
                      md={2}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <img src={`${fs2}`} className="img-fluid" />
                    </Col>
                    <Col
                      md={3}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <img src={`${fs3}`} className="img-fluid" style={{width:'250px'}} />
                    </Col>
                    <Col
                      md={2}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <img src={`${fs4}`} className="img-fluid" />
                    </Col>
                    <Col
                      md={2}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <img src={`${fs5}`} className="img-fluid" />
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </Fade>
        </section>
      </Fade>
      <section className="mt-5">
        <Fade>
          <div className="placement-image">
            <Container>
              <div className="placement-text">
                <h3
                  style={{
                    color: "#fff",
                    maxWidth: "100%",
                    overflow: "hidden",
                    fontSize: "45px",
                    fontWeight: "bold",
                    textShadow: "2px 2px 4px rgba(255, 0, 0, 0.5)", // Text shadow with light red color
                  }}
                >
                  Placements
                </h3>
              </div>
            </Container>
          </div>
        </Fade>
      </section>
      <section className="mt-5">
        <Container>
          <Row>
            <Col md={4}>
              <div
                style={{
                  position: "relative",
                  maxWidth: "100%",
                  overflow: "hidden",
                  paddingBottom: "60px",
                  paddingRight: "50px",
                }}
              >
                <Card style={{ maxWidth: "100%", overflow: "hidden" }}>
                  <img src={`${ps1}`} />
                </Card>
                <div className="loader-wrapper">
                  <img src={`${loader}`} className="loader" />
                </div>
              </div>
            </Col>
            <Col md={8} style={{ paddingLeft: "10px" }}>
              <p style={{ fontSize: "20px", textAlign: "justify" }}>
                <span style={{ fontWeight: "bolder" }}>Placement Cell</span>{" "}
                Every academic year will see a placement fair accompanying major
                recruiters including Vogue Interior, Fine Edge ,Orient Craft
                ,P.C Chandra Jewellers , Marks & Spencer to name a few. Prior to
                the placement fair we provide workshops on
                <span style={{ fontWeight: "bolder" }}>
                  {" "}
                  Resume building, cover letter writing, cracking aptitude test
                </span>
                along with their regular curriculum of theory and practicals
                which give the students a hands-on experience in understanding
                the dynamics of the industry. Conducting mock interviews,
                encouraging students to build good connections in the industry,
                helping students to understand the job profile and the growth
                prospects of any job opportunity is a vital part of the
                placement cell. Not only in-house faculties but also live
                interaction with industry experts, professional trainers
                contribute to grooming and shaping the students in order to face
                the competitive industrial world.
              </p>
            </Col>
          </Row>
          {/* second row here */}

          <Row className="mt-5">
            <Col md={8} style={{ paddingLeft: "10px" }}>
              <p style={{ fontSize: "20px", textAlign: "justify" }}>
                <span style={{ fontWeight: "bolder" }}>
                  Personal Grooming & Soft-Skill Training{" "}
                </span>{" "}
                First impressions leave an everlasting imprint on someone’s
                mind. Body language, Code of Conduct, Physical appearance,
                Dressing Styles, Communication techniques, Public speaking, and
                General etiquette are key workshops organized by INSD to ensure
                holistic growth & development of students which not only prepare
                them for interviews but also improve their overall confidence
                and self-esteem.
              </p>
            </Col>
            <Col md={4}>
              <div
                style={{
                  position: "relative",
                  maxWidth: "100%",
                  overflow: "hidden",
                  paddingBottom: "60px",
                  paddingRight: "50px",
                }}
              >
                <Card style={{ maxWidth: "100%", overflow: "hidden" }}>
                  <img src={`${ps2}`} />
                </Card>
                <div className="loader-wrapper">
                  <img src={`${loader}`} className="loader" />
                </div>
              </div>
            </Col>
          </Row>

          {/* third Row */}
          <Row className="mt-5">
            <Col md={4}>
              <div
                style={{
                  position: "relative",
                  maxWidth: "100%",
                  overflow: "hidden",
                  paddingBottom: "60px",
                  paddingRight: "50px",
                }}
              >
                <Card style={{ maxWidth: "100%", overflow: "hidden" }}>
                  <img src={`${ps3}`} />
                </Card>
                <div className="loader-wrapper">
                  <img src={`${loader}`} className="loader" />
                </div>
              </div>
            </Col>
            <Col md={8} style={{ paddingLeft: "10px" }}>
              <p style={{ fontSize: "20px", textAlign: "justify" }}>
                <span style={{ fontWeight: "bolder" }}>
                  {" "}
                  Career Development Cell
                </span>{" "}
                Making a career choice can be a daunting task for most young
                aspirants, especially with multiple options today. At INSD, we
                ensure that the chosen career path is most suited for the
                student and aligns with his/her interests and abilities. Special
                emphasis is laid on preparing students to work towards
                self-growth to meet the increasing demands of the industry in
                the most comprehensive yet competitive manner. Personalised
                Counselling and guidance, building IQ (Intelligence Quotient)
                and EQ (Emotional Quotient) to survive and sustain in stressful
                situations, and keeping the work-life balance to enjoy their
                chosen career paths is the cornerstone of all our endeavours for
                a student.
              </p>
            </Col>
          </Row>

          {/* fourth */}
          <Row className="mt-5">
            <Col md={8} style={{ paddingLeft: "10px" }}>
              <p style={{ fontSize: "20px", textAlign: "justify" }}>
                <span style={{ fontWeight: "bolder" }}>
                Entrepreneurship Development Cell
                </span>{" "}
                Entrepreneurship Development Cell An essential component to
                growth today is making start-ups realistic, feasible and
                sustainable. The Entrepreneurship Development Cell will provide
                workshops, activities, and interactive sessions to work out
                set-ups, raise finances, accounting and inculcate managerial and
                people skills required to run a successful business. The design
                industry is such that every individual has his/her own creative
                way of working, making it imperative to have their own business
                model that they can set up and run by themselves to achieve
                their goals and live their dreams.
              </p>
            </Col>
            <Col md={4}>
              <div
                style={{
                  position: "relative",
                  maxWidth: "100%",
                  overflow: "hidden",
                  paddingBottom: "60px",
                  paddingRight: "50px",
                }}
              >
                <Card style={{ maxWidth: "100%", overflow: "hidden" }}>
                  <img src={`${ps4}`} />
                </Card>
                <div className="loader-wrapper">
                  <img src={`${loader}`} className="loader" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default InternationalExposure;
