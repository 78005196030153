import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { MdOutlineEmail } from "react-icons/md";
import { AiOutlinePhone } from "react-icons/ai";
import { TbSlash } from "react-icons/tb";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import ContactUsModal from "./modals/ContactUsModal";
import logoImg from '../assets/images/logo-img.png';
import insdlogo from '../assets/images/WhatsApp Image 2024-04-19 at 14.26.33.jpeg';
import { useNavigate } from "react-router-dom";
import "../App.css";

const Header = () => {
  const [contactModal, setContactModal] = useState(false);
  const [nameModal, setNameModal] = useState('');
  const navigate = useNavigate();

  const handleContact = (e) => {
    setNameModal(e.target.innerText);
    setContactModal(true);
  }
  const handleFashion = () => {
    navigate('/fashion-design')
  }
  const handleGraphic = () => {
    navigate('/graphic-design')
  }
  const handleContactClick = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight, // Scroll to the bottom of the document
      behavior: "smooth" // Smooth scrolling behavior
    });
  };

  const animation = () => {
    navigate('/animation-design')
  }
  const international = () => {
    navigate('/placement')

  }
  const handleHome = () => {
    navigate('/')
  }
  const interior = () => {
    navigate('/interior-design')
  }
  const toggleModal = (isOpen) => {
    setContactModal(isOpen);
  };
  return (
    <>
      <div>
        <header className="top_header">
          <div className="subheader at_top">
            <div className="subheader_h py-2">
              <div className="subheader_cell at_left">
                <div className="w_text ush_text nowrap icon_atleft at_left">
                  <a href="insdmeerut@gmail.com" className="w-text-h">
                    <MdOutlineEmail style={{ fontSize: "25px" }} />{" "}
                    <span>insdmeerut@gmail.com</span>
                  </a>
                </div>
                <div className="w_html ush_html ush_text at_left">
                  <a href="tel:+917887836661">
                    <AiOutlinePhone
                      style={{ fontSize: "25px", marginRight: "5px" }}
                    />
                    <span>+919410-501-502</span>
                  </a>
                  {/* <span style={{fontSize:'28px',lineHeight:'1.5'}}> /</span>  */}
                  <TbSlash style={{ fontSize: "28px" }} />
                  <a href="tel:+917887836662">
                    <AiOutlinePhone
                      style={{ fontSize: "25px", margin: "0px 1px" }}
                    />
                    <span>+917874155490 </span>
                  </a>
                  {/* <div className="third-no"> */}
                  <span className="third-no">
                  <TbSlash style={{ fontSize: "28px" }} />
                  <a href="tel:+917887836662">
                    <AiOutlinePhone
                      style={{ fontSize: "25px", margin: "0px 1px" }}
                    />
                    <span>+919818070864 </span>
                  </a>
                  </span>
                  {/* </div> */}
                </div>
                <div className="w_text ush_text">
                  <span className="w_text_h">
                    <span className="adm" style={{fontSize:'15px', cursor:'pointer'}} onClick={(e)=>handleContact(e)}>
                      ADMISSIONS OPEN FOR UG/PG &amp; DIPLOMA
                    </span>
                  </span>
                </div>
              </div>
              <div className="subheader_cell at_right">
                <div className="w_socials">
                  <div className="w-socials-list">
                    <div className="w-socials-item facebook">
                      <a
                        href="https://www.facebook.com/insdmeerut"
                        className="w_socials_item_link"
                        title="facebook"
                        target="_blank"
                      >
                        <span className="w_socials_item_link_hover">
                          <FaFacebook className="fa-facebook"/>
                        </span>
                      </a>
                    </div>
                    <div className="w-socials-item facebook">
                      <a
                        href="#"
                        className="w_socials_item_link"
                        title="facebook"
                        target="_blank"
                      >
                        <span className="w_socials_item_link_hover">
                          <FaTwitter className="fa-twitter"/>
                        </span>
                      </a>
                    </div>
                    <div className="w-socials-item facebook">
                      <a
                        href="#"
                        className="w_socials_item_link"
                        title="facebook"
                        target="_blank"
                      >
                        <span className="w_socials_item_link_hover">
                          <FaLinkedin className="fa-linkdin"/>
                        </span>
                      </a>
                    </div>
                    <div className="w-socials-item facebook">
                      <a
                        href="https://www.instagram.com/insdmeerut"
                        className="w_socials_item_link"
                        title="facebook"
                        target="_blank"
                      >
                        <span className="w_socials_item_link_hover">
                          <FaInstagram className="fa-insta"/>
                        </span>
                      </a>
                    </div>
                    <div className="w-socials-item facebook">
                      <a
                        href="#"
                        className="w_socials_item_link"
                        title="facebook"
                        target="_blank"
                      >
                        <span className="w_socials_item_link_hover">
                          <FaYoutube className="fa-tube" />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Navbar bg="light" expand="lg" style={{ overflow: 'hidden', maxWidth:'100%' }}>
  <Container fluid>
    <Navbar.Toggle aria-controls="basic-navbar-nav" className="ml-auto"/>
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav >
      <Navbar.Brand  className="shown-brand1">
          <img src={insdlogo} style={{ width: '100%', maxWidth: '300px', height: 'auto' }} alt="Your Logo" />
        </Navbar.Brand>
        <Nav.Link onClick={handleHome} style={{ fontSize: '18px', fontWeight: '500', color: '#000' }}>Home</Nav.Link>
        <Nav.Link  onClick={handleFashion} style={{ fontSize: '18px', fontWeight: '500', color: '#000' }}>Fashion Designing</Nav.Link>
        <Nav.Link onClick={handleGraphic} style={{ fontSize: '18px', fontWeight: '500', color: '#000' }}>Graphics Designing</Nav.Link>
        <Navbar.Brand  className="shown-brand2">
          <img src={insdlogo} style={{ width: '100%', maxWidth: '300px', height: 'auto' }} alt="Your Logo" />
        </Navbar.Brand>
      
        <Nav.Link onClick={interior}  style={{ fontSize: '18px', fontWeight: '500', color: '#000' }}>Interior Designing</Nav.Link>
        <Nav.Link onClick={animation} style={{ fontSize: '18px', fontWeight: '500', color: '#000' }}>Animation Designing</Nav.Link>
        <Nav.Link onClick={international} style={{ fontSize: '18px', fontWeight: '500', color: '#000' }}>International Exposure</Nav.Link>

        <Nav.Link href="#contact"  onClick={handleContactClick}>Contact</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>



          {/* </div> */}
        </header>
        <ContactUsModal contactModal={contactModal} nameModal={nameModal} toggleModal={toggleModal}/>
      </div>
    </>
  );
};

export default Header;
