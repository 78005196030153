import React, {useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContactUsModal from "./modals/ContactUsModal";
import { Form } from "react-bootstrap";
const AdmissionForm = () => {
  const [contactModal, setContactModal] = useState(false);
  const [nameModal, setNameModal] = useState('');
  const handleContact = (e) => {
    console.log('ee', e.target.innerText)
    setNameModal(e.target.innerText);
    setContactModal(true);
  }
  const toggleModal = (isOpen) => {
    setContactModal(isOpen);
  };

  const handleAdmissionForm = () => {};
  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          className="btns"
          id="contact-sticky-button"
          style={{ maxWidth: "100%", overflow: "hidden" }}
          onClick={(e) => handleContact(e)}
        >
          <span
            style={{ cursor: "pointer", color: "#fff", padding: "5px" }}
            className="pum-trigger-admission"
          >
            Contact Us
          </span>
        </div>
        <div
          className="btns"
          id="admission-sticky-button"
          style={{ overflow: "hidden", maxWidth: "100%", cursor: "pointer" }}
          onClick={handleAdmissionForm}
        >
          <span className="pum-trigger-admission">Admission Form</span>
        </div>
        <div
          className="btns"
          id="getfreecoun-sticky-button"
          style={{ overflow: "hidden", maxWidth: "100%" }}
          onClick={(e) => handleContact(e)}
        >
          <span
            className="pum-trigger-getfreecoun"
            style={{ cursor: "pointer" }}
          >
            Get Free Career Counseling
          </span>
        </div>
        <section
          className="main-admission-form"
          style={{ maxWidth: "100%", overflow: "hidden" }}
        >
          <div
            className="admission-form-image"
            style={{ maxWidth: "100%", overflow: "hidden" }}
          >
            <div className="style-overlay"></div>
            <div
              className="admission-heading"
              style={{ maxWidth: "100%", overflow: "hidden" }}
            >
              <div className="admission-heading-content" >
                <h1 style={{ maxWidth: "100%", overflow: "hidden" }}>
                  Admission Form
                </h1>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="form-fields" style={{ height: "auto" }}>
            {/* <Row> */}
            <Container>
              <Row>
              <Col md={12}>
              <div className="main-form-fields">
                <div className="adm-form-fields">
                  <Form   action="https://formspree.io/f/mvoevrna"
  method="POST">
                    <Form.Group className="mb-3">
                      <Form.Select aria-label="Default select example" name="courses" required>
                        <option>*Select Course</option>
                        <option value="Fashion Designing">Fashion Designing</option>
                        <option value="Interior Designing">Interior Designing</option>
                        <option value="Luxury Designing">Luxury Designing</option>
                        <option value="Animation Designing">Animation Designing</option>
                        <option value="Graphic Designing">Graphic Designing</option>
                        <option value="Jewellary Management">Jewellary Management</option>

                      </Form.Select>
                    </Form.Group>
                    {/* <Form.Group className="mb-3">
                      <Form.Select aria-label="Default select example">
                        <option>*Select Duration</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </Form.Group> */}
                    <Form.Group className="mb-3">
                      <Form.Control type="text" placeholder="*Name (Mr./Mrs)"  name="name" required/>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Address"
                        name="Address"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control type="text" placeholder="*State" name="state"  required/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control type="text" placeholder="*District" name="District" required/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control type="text" placeholder="Pincode" name="pincode" required/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control type="email" placeholder="*Email" name="email" required/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control type="text" placeholder="Mobile Number *" name="contact number" required/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control type="date"  name="date" required/>
                    </Form.Group>

                    <div className="d-flex mb-4">
                      <div
                        className="radio-btns px-2"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Form.Check type="radio" aria-label="radio 1" name="Gender" value="Male"/>
                        <label for="html" className="pt-1">
                          Male
                        </label>
                      </div>
                      <div
                        className="radio-btns px-2"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Form.Check type="radio" aria-label="radio 1" name="Gender" value="female" />
                        <label for="html" className="pt-1">
                          Female
                        </label>
                      </div>
                    </div>
                    {/* <Form.Group className="mt-3 mb-3">
                      <Form.Control
                        type="text"
                        placeholder="*Enter your Edu Qualification"
                      />
                    </Form.Group> */}
                     <div className="submit">
                    <button className="button">Submit</button>
                  </div>
                  </Form>
                 
                </div>
              </div>
              </Col>
              </Row>
            </Container>
            {/* </Row> */}
          </div>
        </section>
      </div>
      <ContactUsModal  contactModal={contactModal} nameModal={nameModal} toggleModal={toggleModal}/>

    </>
  );
};

export default AdmissionForm;
