import React, { useState } from "react";
import "../App.css";
import animation from "../assets/images/Animationdesign.jpg";
import parallex from "../assets/images/parallex.webp";
import { Row, Container, Col, Form } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import ContactUsModal from "./modals/ContactUsModal";
import { useNavigate } from "react-router-dom";

const Animation = () => {
  const [key, setKey] = useState("Year One");
  const [key1, setKey1] = useState("Year One");
  const [key2, setKey2] = useState("Year One");
  const [key3, setKey3] = useState("Year One");
  const [key4, setKey4] = useState("6 Months");
  const [key5, setKey5] = useState("6 Months");
  const [key6, setKey6] = useState("6 Months");
  const [contactModal, setContactModal] = useState(false);
  const [nameModal, setNameModal] = useState("");
  const navigate = useNavigate();
  const handleContact = (e) => {
    console.log("ee", e.target.innerText);
    setNameModal(e.target.innerText);
    setContactModal(true);
  };
  const toggleModal = (isOpen) => {
    setContactModal(isOpen);
  };
  const handleAdmissionForm = () => {
    navigate("/admission-form");
  };

  return (
    <>
      <div
        className="main-courses-div"
        // style={{ backgroundImage: `url(${parallex})`, overflow: "hidden" }}
      >
        <div className="main-course-img">
          <img src={animation} style={{ width: "100%", height: "100%" }} />
        </div>
        <div
          className="career-paths-main mt-5"
          style={{ maxWidth: "100%", overflow: "hidden" }}
        >
          <Container>
            <Row>
              <h1 style={{ maxWidth: "100%", overflow: "hidden" }}>
                Career Paths:
              </h1>
              <Col md={4}>
                <div className="career-path-div">
                  <div className="career-paths mt-4">
                    <ul>
                      <li>3d Model</li>
                      <li>3d Animation</li>
                      <li>Titling Artist</li>
                      <li>Maya Modeler</li>
                      <li>Maya Rigging</li>
                      <li>Artist</li>
                      <li>Maya Animator</li>
                      <li>Maya Texturing Artist</li>
                      <li>Maya Lighting Artist</li>
                    </ul>
                  </div>
                </div>
              </Col>

              {/* second column starts here */}

              <Col md={4}>
                <div className="career-path-div">
                  <div className="career-paths mt-4">
                    <ul>
                      <li>Maya Dynamics Artist</li>
                      <li>Advertising</li>
                      <li>Online And Print News Media</li>
                      <li>Film & Television</li>
                      <li>Cartoon Production</li>
                      <li>Theater</li>
                      <li>Video Gaming</li>
                      <li>E-Learning</li>
                      <li>Compositor</li>
                    </ul>
                  </div>
                </div>
              </Col>
              {/* Third column starts here */}
              <Col md={4}>
                <div className="career-path-div">
                  <div
                    className="career-paths mt-4"
                    style={{ paddingBottom: "84px" }}
                  >
                    <ul>
                      <li>Matchmoving Artist</li>
                      <li>FxArtist </li>
                      <li>Lighting Artist</li>
                      <li>Pre-Viz Artist</li>
                      <li>Motion Graphics Artist</li>
                      <li>Roto Artist</li>
                      <li>Clean-Up Artist</li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="btns"
          id="contact-sticky-button"
          style={{ maxWidth: "100%", overflow: "hidden" }}
          onClick={(e) => handleContact(e)}
        >
          <span
            style={{ cursor: "pointer", color: "#fff", padding: "5px" }}
            className="pum-trigger-admission"
          >
            Contact Us
          </span>
        </div>
        <div
          className="btns"
          id="admission-sticky-button"
          style={{ overflow: "hidden", maxWidth: "100%", cursor: "pointer" }}
          onClick={handleAdmissionForm}
        >
          <span className="pum-trigger-admission">Admission Form</span>
        </div>
        <div
          className="btns"
          id="getfreecoun-sticky-button"
          style={{ overflow: "hidden", maxWidth: "100%" }}
          onClick={(e) => handleContact(e)}
        >
          <span
            className="pum-trigger-getfreecoun"
            style={{ cursor: "pointer" }}
          >
            Get Free Career Counseling
          </span>
        </div>
        <div className="essential-duties">
          <Container>
            <Row>
              <div
                className="essential-text mt-5"
                style={{ maxWidth: "100%", overflow: "hidden" }}
              >
                <h2
                  className="text-center"
                  style={{ maxWidth: "100%", overflow: "hidden" }}
                >
                  Essential Duties and Responsibilities of a Animation Designer
                </h2>
                <div className="d-flex f-design">
                  <Col md={6}>
                    <div className="essential-text-data mt-4 mx-5">
                      <ul style={{ color: "#333333", fontSize: "18px" }}>
                        <li>
                          using a range of materials, including modelling clay,
                          plaster, oil paints, watercolours and acrylics
                        </li>
                        <li>
                          developing the timing and pace of the movements of a
                          character or object during the sequence of images and
                          ensuring they follow the soundtrack and audio
                          requirements
                        </li>
                        <li>
                          using technical software packages, such as Flash, 3ds
                          Max, Maya, LightWave, Softimage and Cinema 4D
                        </li>
                        <li>
                          building up accurate, detailed, frame-by-frame visuals
                        </li>
                      </ul>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div
                      className="essential-text-data mt-4 mx-5"
                      style={{ color: "#333333", fontSize: "18px" }}
                    >
                      <ul>
                        <li>
                          recording dialogue and working with editors to
                          composite the various layers of animation
                          (backgrounds, special effects, characters and
                          graphics) in order to produce the finished piece
                        </li>
                        <li>
                          working to production deadlines and meeting clients’
                          commercial requirements
                        </li>
                        <li>
                          working as part of a broader production team, which
                          might include liaising with printers, copywriters,
                          photographers, designers, account executives, website
                          designers or marketing specialists
                        </li>
                        <li>
                          dealing with diverse business cultures, delivering
                          presentations and finding funding.
                        </li>
                      </ul>
                    </div>
                  </Col>
                </div>
              </div>
            </Row>
          </Container>
        </div>
        <div>
          <Row>
            <Container>
              <section className="contact-us-form mt-5 mb-5">
                <Container>
                <div className="booking-form">
            <Form  action="https://formspree.io/f/mvoevrna"
  method="POST">
              <div className="form-group">
                <div className="form-checkbox">
                  <label>
                    <span className="text-center">Book your courses..</span>
                  </label>
                </div>
                <Row className="mt-2">
                  <Col md={6}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        Name
                      </span>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        required
                        placeholder="Your Name"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        E-MAIL ADDRESS
                      </span>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        required
                        placeholder="Your Email"
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={6}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        PHONE NUMBER
                      </span>
                      <input
                        className="form-control"
                        type="text"
                        name="contact number"
                        required
                        placeholder="Your contact number"
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        SELECT COURSE
                      </span>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        name="courses"
                        required
                      >
                       <option>Open this select menu</option>
                        <option value="Fashion Design">Fashion Design</option>
                        <option value="Interior Design">Interior Design</option>
                        <option value="Graphic Design">Graphic Design</option>
                        <option value="Animation Design">Animation Design</option>
                        <option value="Luxury Management">Luxury Management</option>
                        <option value="Jewellary Design">Jewellary Design</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-btn">
                      <button className="submit-btn">Book Now</button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
                </Container>
              </section>
            </Container>
          </Row>
        </div>
        <div className="fashion-designing-courses mt-5">
          <Container>
            <Row>
              <div
                className="fashion-designing-text"
                style={{
                  maxWidth: "100%",
                  overflow: "hidden",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                }}
              >
                <h1 style={{ maxWidth: "100%", overflow: "hidden" }}>
                  Animation Designing Courses in Meerut : UG / PG Courses
                </h1>
                <div className="fas-sub-heading mt-5">
                  <h3>
                    UG: Bachelors Programmes – 4 Years Specialization Course
                    (Eligibility : 10+2)
                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => setKey(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="Year One"
                        title="Year One"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">
                                1st Semester – 20 credits
                              </th>
                              <th className="py-3 px-4">
                                2nd Semester – 20 credits
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Drawing Concepts
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Advanced Photoshop & Texturing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Pre -Production Digital Art & Design – Theory
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Anatomy For Animation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Digital Art Design – Practical
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Cel Animation – Practical
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction To Pc Skills And Programming
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Principles Of Animation, Media Laws & Ethics
                                Film Analysis
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Drawing With Computer
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                2d Animation
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Two" title="Year Two">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">
                                3rd Semester – 22 credits
                              </th>
                              <th className="py-3 px-4">
                                4th Semester – 20 credits
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction To 3d Studio Max
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction To Maya
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                3d Modeling In Max
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                3d Modeling In Maya
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Shading & Texturing In Max
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Shading & Texturing In Maya
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Lighting & Camera Techniques In Max
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Lighting & Camera Techniques In Maya
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Videography
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Character Setup & Rigging In Maya
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              ></td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction To Mel Scripting
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Three" title="Year Three">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">
                                5th Semester – 22 credits
                              </th>
                              <th className="py-3 px-4">
                                6th Semester – 20 credits
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Maya Effects, Particles, Dynamics & Rendering
                                Techniques
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                3d Group Project Or Internship
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Compositing Editing & Sound For Animation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                3d Animation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Creating Demo Reel
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Environment Studies
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Four" title="Year Four">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">
                                7th Semester – 24 Credits
                              </th>
                              <th className="py-3 px-4">
                                8th Semester – 20 Credits
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Industry Projects
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Internship & On the Job Training
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Entrepreneurship
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Market Dynamics
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                    </Tabs>
                  </div>
                </div>

                {/* second tab */}

                <div className="fas-sub-heading mt-5">
                  <h3>
                    UG: Bachelors Programmes – 3 Years Specialization Course
                    (Eligibility : 10+2)
                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key1}
                      onSelect={(k) => setKey1(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="Year One"
                        title="Year One"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">
                                1st Semester – 20 credits
                              </th>
                              <th className="py-3 px-4">
                                2nd Semester – 20 credits
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Drawing Concepts
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Advanced Photoshop & Texturing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Pre -Production
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Anatomy For Animation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Digital Art & Design – Theory
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Cel Animation – Practical
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Digital Art Design – Practical
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Principles Of Animation, Media Laws & Ethics
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction To Pc Skills And Programming
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Film Analysis
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Drawing With Computer
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                2d Animation
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Two" title="Year Two">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">
                                3rd Semester – 22 credits
                              </th>
                              <th className="py-3 px-4">
                                4th Semester – 20 credits
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction To 3d Studio Max
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction To Maya
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                3d Modeling In Max
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                3d Modeling In Maya
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Shading & Texturing In Max
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Shading & Texturing In Maya
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Lighting & Camera Techniques In Max
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Lighting & Camera Techniques In Maya
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Videography
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Character Setup & Rigging In Maya
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              ></td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction To Mel Scripting
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Three" title="Year Three">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">
                                5th Semester – 22 credits
                              </th>
                              <th className="py-3 px-4">
                                6th Semester – 20 credits
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Maya Effects, Particles, Dynamics & Rendering
                                Techniques
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                3d Group Project Or Internship
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Compositing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Editing & Sound For Animation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                3d Animation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Creating Demo Reel
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Environment Studies
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                    </Tabs>
                  </div>
                </div>

                {/* third Tab */}

                <div className="fas-sub-heading mt-5">
                  <h3>
                    Animation Courses: Pg Course – 2 Years (Eligibility:
                    Graduate)
                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key2}
                      onSelect={(k) => setKey2(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="Year One"
                        title="Year One"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">
                                1st Semester – 24 Credits
                              </th>
                              <th className="py-3 px-4">
                                2nd Semester – 22 Credits
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Drawing Concepts
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Advanced Photoshop & Texturing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Pre -Production
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Anatomy For Animation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Digital Art & Design – Theory
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Cel Animation – Practical
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Digital Art Design – Practical
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Principles Of Animation, Media Laws & Ethics
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction To Pc Skills And Programming
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Film Analysis
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Drawing With Computer
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                2d Animation
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Two" title="Year Two">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">
                                3rd Semester – 24 Credits
                              </th>
                              <th className="py-3 px-4">
                                4th Semester – 22 Credits
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction To 3d Studio Max
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                3d Modeling In Maya
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                3d Modeling In Max
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Shading & Texturing In Maya
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Shading & Texturing In Max
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Lighting & Camera Techniques In Maya
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Lighting & Camera Techniques In Max
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Character Setup & Rigging In Maya
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Videography
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Introduction To Mel Scripting
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
                {/* fourth */}
                <div className="fas-sub-heading mt-5">
                  <h3>2 Years Advance Course (Eligibility 10+2)</h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key2}
                      onSelect={(k) => setKey2(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="Year One"
                        title="Year One"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">
                                1st Semester – 20 Credits
                              </th>
                              <th className="py-3 px-4">
                                2nd Semester – 22 Credits
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Pre-Production
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Visual Communication And Animation Processes
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Sketching & Drawing
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                3d Computer Animation 1
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                3d Material Projects
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Film-Making
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Drawing Concepts Digital Art Design – Practical
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Animation Production 1 – Stop Motion
                                Communication Skills
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Digital Art & Design – Theory
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Digital Art Design – Practical
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              ></td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction To Pc Skills And Programming
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              ></td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Drawing With Computer
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              ></td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Two" title="Year Two">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">
                                3rd Semester – 20 Credits
                              </th>
                              <th className="py-3 px-4">
                                4th Semester – 24 Credits
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Pre- Production 2
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Final Production
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                3d Computer Animation 2
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Business And Management Skills
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                2d Computer Animation 2
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Research Seminar
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Animation Production 3 – Social Narrative
                                Animation
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Pre- Production 1
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              ></td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                3d Computer Animation 2
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                    </Tabs>
                  </div>
                </div>

                {/*  */}

                {/* fifth Tab */}

                <div className="fas-sub-heading mt-5">
                  <h3>1 Year Advance Course (Eligibility : 10+2)</h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key3}
                      onSelect={(k) => setKey3(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="Year One"
                        title="Year One"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">
                                1st Semester – 20 credits
                              </th>
                              <th className="py-3 px-4">
                                2nd Semester – 20 credits
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Drawing Concepts
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Advanced Photoshop & Texturing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Pre -Production
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Anatomy For Animation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Digital Art & Design – Theory
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Cel Animation – Practical
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Digital Art Design – Practical
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Principles Of Animation, Media Laws & Ethics
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction To Pc Skills And Programming
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Film Analysis
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Drawing With Computer
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                2d Animation
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                    </Tabs>
                  </div>
                </div>

                {/* fifth tab */}
                <div className="fas-sub-heading mt-5">
                  <h3>
                    Animation Course In Meerut Certification – 6 Months
                    (Eligibility: 10th)
                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key4}
                      onSelect={(k) => setKey4(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="6 Months"
                        title="6 Months"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">Course Content </th>
                              <th className="py-3 px-4">Pre -Production</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Digital Art Design – Practical
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction To Pc Skills And Programming
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Drawing With Computer
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Basic Photoshop & Texturing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Anatomy For Animation
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Cel Animation – Practical
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Principles Of Animation
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                2d Animation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Softwares
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Adobe Photoshop, Adobe After Effects, Adobe
                                Premiere, Adobe Audition, Autodesk 3dsmax
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </div>
      <ContactUsModal
        contactModal={contactModal}
        nameModal={nameModal}
        toggleModal={toggleModal}
      />
    </>
  );
};

export default Animation;
