import React, {useState} from "react";
import graphicDesign from '../assets/images/graphicdesign.jpg';
import { Container, Row, Col, Form } from "react-bootstrap";
import ContactUsModal from "./modals/ContactUsModal";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";


const GraphicDesigning = () => {
    const [key, setKey] = useState("Year One");
    const [key1, setKey1] = useState("Year One");
    const [key2, setKey2] = useState("Year One");
    const [key3, setKey3] = useState("Year One");
    const [key4, setKey4] = useState("Year One");
    const [key5, setKey5] = useState("6 Months");
    const [key6, setKey6] = useState("6 Months");
    const [contactModal, setContactModal] = useState(false);
    const [nameModal, setNameModal] = useState('');
    const navigate = useNavigate();
    const handleContact = (e) => {
      console.log('ee', e.target.innerText)
      setNameModal(e.target.innerText);
      setContactModal(true);
    }
    const toggleModal = (isOpen) => {
      setContactModal(isOpen);
    };
    const handleAdmissionForm = () => {
      navigate('/admission-form');
    }
    return (
        <>
         <div className="main-courses-div">
         <div className="main-course-img">
          <img src={graphicDesign} style={{ width: "100%", height: "100%" }} />
        </div>
        <div
          className="career-paths-main mt-5"
          style={{ maxWidth: "100%", overflow: "hidden" }}
        >
          <Container>
            <Row>
              <h1 style={{ maxWidth: "100%", overflow: "hidden" }}>
                Career Paths:
              </h1>
              <Col md={4}>
                <div className="career-path-div">
                  <div className="career-paths mt-4">
                    <ul>
                      <li>Graphic Artist</li>
                      <li>Packaging Designer</li>
                      <li>Advertising Art Director</li>
                      <li>Web/Interactive Designer</li>
                      <li>User Interface Designer</li>
                      <li>Illustrator</li>
                      <li>Dtp Operator</li>
                    </ul>
                  </div>
                </div>
              </Col>

              {/* second column starts here */}

              <Col md={4}>
                <div className="career-path-div">
                  <div className="career-paths mt-4">
                    <ul>
                      <li>Layout Designer</li>
                      <li>Developer For Interactive And <br/> E-Learning</li>
                      <li>Digital Storyboard Designer</li>
                      <li>2d Animator</li>
                      <li>Art or Creative Director</li>
                      <li>Architecture and Engineering <br/> Drafter</li>
                    </ul>
                  </div>
                </div>
              </Col>
              {/* Third column starts here */}
              <Col md={4}>
                <div className="career-path-div">
                  <div className="career-paths mt-4 pb-5">
                    <ul>
                      <li>Video and Film Editor</li>
                      <li>Graphic Designer</li>
                      <li>Product or Industrial Designer</li>
                      <li>Marketing Manager</li>
                      <li>Campaign Manager</li>
                      <li>Multimedia Artist</li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div
        className="btns"
        id="contact-sticky-button"
        style={{ maxWidth: "100%", overflow: "hidden" }}
        onClick={(e)=>handleContact(e)}
      >
       
       
         <span style={{cursor:'pointer', color:'#fff', padding:'5px'}} className="pum-trigger-admission">
         Contact Us
        </span>
      </div>
      <div
        className="btns"
        id="admission-sticky-button"
        style={{ overflow: "hidden", maxWidth: "100%", cursor:'pointer' }}
        onClick={handleAdmissionForm}
      >
        <span className="pum-trigger-admission">
          Admission Form
        </span>
      </div>
      <div
        className="btns"
        id="getfreecoun-sticky-button"
        style={{ overflow: "hidden", maxWidth: "100%" }}
        onClick={(e)=>handleContact(e)}
      >
        <span className="pum-trigger-getfreecoun" style={{cursor:'pointer'}}>
          Get Free Career Counseling
        </span>
      </div>
        <div className="essential-duties">
          <Container>
            <Row>
              <div
                className="essential-text mt-5"
                style={{ maxWidth: "100%", overflow: "hidden" }}
              >
                <h2
                  className="text-center"
                  style={{ maxWidth: "100%", overflow: "hidden" }}
                >
                  Essential Duties and Responsibilities of a Graphic Designer
                </h2>
                <div className="d-flex f-design">
                  <Col md={6}>
                    <div className="essential-text-data mt-4 mx-5">
                      <ul style={{ color: "#333333", fontSize: "18px" }}>
                        <li>
                        Study design briefs and determine requirements
                        </li>
                        <li>
                        Schedule projects and define budget constraints
                        </li>
                        <li>
                        Conceptualize visuals based on requirements
                        </li>
                        <li>Prepare rough drafts and present ideas</li>
                        <li>Develop illustrations, logos and other designs using software or by hand</li>

                      </ul>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div
                      className="essential-text-data mt-4 mx-5"
                      style={{ color: "#333333", fontSize: "18px" }}
                    >
                      <ul>
                        <li>
                        Use the appropriate colors and layouts for each graphic
                        </li>
                        <li>
                        Work with copywriters and creative director to produce final design

                        </li>
                        <li>
                        Test graphics across various media
                        </li>
                        <li>
                        Amend designs after feedback
                        </li>
                        <li>
                        Ensure final graphics and layouts are visually appealing and on-brand
                        </li>
                      </ul>
                    </div>
                  </Col>
                </div>
              </div>
            </Row>
          </Container>
        </div>
        <div>
            <Row>
                <Container>
                <section className="contact-us-form mt-5 mb-5">
        <Container>
        <div className="booking-form">
            <Form  action="https://formspree.io/f/mvoevrna"
  method="POST">
              <div className="form-group">
                <div className="form-checkbox">
                  <label>
                    <span className="text-center">Book your courses..</span>
                  </label>
                </div>
                <Row className="mt-2">
                  <Col md={6}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        Name
                      </span>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        E-MAIL ADDRESS
                      </span>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={6}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        PHONE NUMBER
                      </span>
                      <input
                        className="form-control"
                        type="text"
                        name="contact number"
                        placeholder="Your contact number"
                        required
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        SELECT COURSE
                      </span>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        name="courses"
                        required
                      >
                       <option>Open this select menu</option>
                        <option value="Fashion Design">Fashion Design</option>
                        <option value="Interior Design">Interior Design</option>
                        <option value="Graphic Design">Graphic Design</option>
                        <option value="Animation Design">Animation Design</option>
                        <option value="Luxury Management">Luxury Management</option>
                        <option value="Jewellary Design">Jewellary Design</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-btn">
                      <button className="submit-btn">Book Now</button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Container>
      </section>
                </Container>
            </Row>
        </div>
        <div className="fashion-designing-courses mt-5">
        <Container>
            <Row>
            <div
                className="fashion-designing-text"
                style={{
                  maxWidth: "100%",
                  overflow: "hidden",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                }}
              >
                <h1 style={{ maxWidth: "100%", overflow: "hidden" }}>
                  Graphic Designing Courses in Meerut : UG / PG Courses
                </h1>
                <div className="fas-sub-heading mt-5">
                  <h3>
                    UG: Bachelors Programmes – 4 Years Specialization Course
                    (Eligibility : 10+2)
                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => setKey(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="Year One"
                        title="Year One"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">1st Semester – 20 credits</th>
                              <th className="py-3 px-4">2nd Semester – 20 credits</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Fundamentals Of Design & Drawing
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Branding And Advertising

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction to pattern making
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Composition
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Typography, Color Theory	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Photography
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Illustration	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Still Life

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Graphic Design, Image Editing	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Packaging – Product

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Page Layout Design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Desktop / Digital Publishing

                              </td>
                            </tr>
                           
                           
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Two" title="Year Two">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">3rd Semester – 22 credits	</th>
                              <th className="py-3 px-4">4th Semester – 20 credits</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Interface Design/ Web Design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Fundamentals Of 2d Animation

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Media Strategy	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               The Art Of Digital Storyboarding

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Language And Practice Of Media Arts	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Digital 2d Animation

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Advance Multi platform Design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Interactive Presentation

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Advance Multi platform Design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Video Editing

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                User Interface Experience	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Sound Editing

                              </td>
                            </tr>
                          
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Three" title="Year Three">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">5th Semester – 22 credits	</th>
                              <th className="py-3 px-4">6th Semester – credits</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Client & Case Study	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Group Project

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Media Strategy	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Full Branding Project On Existing Company

                              </td>
                            </tr>
                           
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Four" title="Year Four">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">7th Semester – 24 Credits	</th>
                              <th className="py-3 px-4">8th Semester – 20 Credits</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Industry Projects	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Internship & On the Job Training

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Entrepreneurship
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Entrepreneurship
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                    </Tabs>

                    {/* second Tab */}

                    <div className="fas-sub-heading mt-5">
                  <h3>
                    UG: Bachelors Programmes – 3 Years Specialization Course
                    (Eligibility : 10+2)
                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key1}
                      onSelect={(k) => setKey1(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="Year One"
                        title="Year One"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">1st Semester – 20 credits	</th>
                              <th className="py-3 px-4">2nd Semester – 20 credits</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Fundamentals Of Design & Drawing	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Branding And Advertising

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Basic Design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Composition

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Idea Visualization	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Photography
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Typography
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Still Life

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Color Theory	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Packaging – Product

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Illustration
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Desktop / Digital Publishing

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Graphic Design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Image Editing	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Page Layout Design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Two" title="Year Two">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">3rd Semester – 22 credits	</th>
                              <th className="py-3 px-4">4th Semester – 20 credits</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Interface Design/ Web Design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Fundamentals Of 2d Animation

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Media Strategy	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                The Art Of Digital Storyboarding

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Language And Practice Of Media Arts	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Digital 2d Animation

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Advance Multi platform	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Interactive Presentation

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Col-design User Interface Experience or Theory	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Packaging Video Editing Sound Editing
g – Product
                              </td>
                            </tr>
                           
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Three" title="Year Three">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">5th Semester – 22 credits</th>
                              <th className="py-3 px-4">6th Semester – credits</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Client & Case Study Full Branding Project On Existing Company	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Group Project

                              </td>
                            </tr>
                           
                          </tbody>
                        </Table>
                      </Tab>
                    </Tabs>
                  </div>
                </div>

                {/* third tab */}
                <div className="fas-sub-heading mt-5">
                  <h3>
                  PG Course – 2 Years (Eligibility: Graduate)

                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key2}
                      onSelect={(k) => setKey2(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="Year One"
                        title="Year One"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">1st Semester – 22 credits	</th>
                              <th className="py-3 px-4">6th Semester – credits</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Fundamentals Of Design & Drawing	 
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Branding And Advertising Composition

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Basic Design Idea, Visualization	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Photography

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Typography, Color Theory	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Still Life Packaging – Product

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                             Illustration, Graphic Design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Desktop / Digital Publishing


                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Image Editing Page Layout Design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                               

                              </td>
                            </tr>
                           
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Two" title="Year Two">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">3rd Semester – 22 credits	</th>
                              <th className="py-3 px-4">6th Semester –  credits</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Interface Design/ Web Design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Fundamentals Of 2d Animation

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Media Strategy	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                The Art Of Digital Storyboarding

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Language And Practice Of Media Arts	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Digital 2d Animation

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Arts Advance Multiplatform Design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Interactive Presentation

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                             User Interface Experience		
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Video Editing Sound Editing
                              </td>
                            </tr>
                           
                          </tbody>
                        </Table>
                      </Tab>
                     
                    </Tabs>
                  </div>
                </div>

                {/* fourth tab */}

                <div className="fas-sub-heading mt-5">
                  <h3>
                  2 Years Advance Course (Eligibility 10+2)


                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key3}
                      onSelect={(k) => setKey3(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="Year One"
                        title="Year One"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">1st Semester – 20 credits	</th>
                              <th className="py-3 px-4">6th Semester – credits</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Fundamentals Of Design & Drawing	 
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Branding And Advertising 

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Basic Design Idea, Visualization	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Composition

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Typography, Color Theory	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Photography

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                             Illustration	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Still Life


                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                           UGraphic Design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                               
                                Packaging – Product

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                          Image Editing, Page Layout Design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                               
                                Desktop / Digital Publishing


                              </td>
                            </tr>
                           
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Two" title="Year Two">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">3rd Semester – 22 credits	</th>
                              <th className="py-3 px-4">6th Semester –  credits</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Interface Design/ Web Design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Fundamentals Of 2d Animation

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Media Strategy	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                The Art Of Digital Storyboarding

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Language And Practice Of Media Arts	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Digital 2d Animation

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Arts Advance Multiplatform Design	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Interactive Presentation

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                             User Interface Experience		
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Video Editing Sound Editing
                              </td>
                            </tr>
                           
                          </tbody>
                        </Table>
                      </Tab>
                     
                    </Tabs>
                  </div>
                </div>

                {/* fifth tab */}
                <div className="fas-sub-heading mt-5">
                  <h3>
                  1 Year Advance Course (Eligibility: 10+2)



                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key4}
                      onSelect={(k) => setKey4(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="Year One"
                        title="Year One"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">1st Semester – 20 credits	</th>
                              <th className="py-3 px-4">2nd Semester – 20 credits</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Fundamentals Of Design & Drawing	 
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Branding And Advertising 

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Typography
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Packaging – Product

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Illustration	
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Desktop / Digital Publishing


                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                             Image Editing		
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Interface Design/ Web Design



                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                        Page Layout Design		
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                               
                                User Interface Experience


                              </td>
                            </tr>
                            
                          </tbody>
                        </Table>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
                                {/* sixth tab */}
                                <div className="fas-sub-heading mt-5">
                  <h3>
                  Certificate Program- 6 Months (Eligibility: 10th)




                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key5}
                      onSelect={(k) => setKey5(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="6 Months"
                        title="6 Months"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">Semester – I	</th>
                              <th className="py-3 px-4">Semester – II</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Fundamentals Of Design & Drawing	 
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Branding And Advertising 

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Typography
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Packaging – Product

                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                              Image Editing		
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                               Desktop / Digital Publishing


                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                            Page Layout Design		
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Interface Design/ Web Design



                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                      Desktop / Digital Publishing		
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                               
                                User Interface Experience


                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                     User Interface Experience		
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                               
                               


                              </td>
                            </tr>
                            
                          </tbody>
                        </Table>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
                  </div>
                </div>
                </div>
                </Row>
                </Container>
        </div>

      </div>
    <ContactUsModal contactModal={contactModal} nameModal={nameModal} toggleModal={toggleModal}/>

        </>
    )
}

export default GraphicDesigning;