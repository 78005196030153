import React, { useState } from "react";
import interiorDesign from "../assets/images/interiordesign.jpg";
import { Container, Row, Col, Form } from "react-bootstrap";
import ContactUsModal from "./modals/ContactUsModal";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";

const InteriorDesigning = () => {
  const [key, setKey] = useState("Year One");
  const [key1, setKey1] = useState("Year One");
  const [key2, setKey2] = useState("Year One");
  const [key3, setKey3] = useState("Year One");
  const [key4, setKey4] = useState("Year One");
  const [key5, setKey5] = useState("6 Months");
  const [key6, setKey6] = useState("6 Months");
  const [contactModal, setContactModal] = useState(false);
  const [nameModal, setNameModal] = useState("");
  const navigate = useNavigate();
  const handleContact = (e) => {
    console.log("ee", e.target.innerText);
    setNameModal(e.target.innerText);
    setContactModal(true);
  };
  const toggleModal = (isOpen) => {
    setContactModal(isOpen);
  };
  const handleAdmissionForm = () => {
    navigate("/admission-form");
  };
  return (
    <>
      <div className="main-courses-div">
        <div className="main-course-img">
          <img src={interiorDesign} style={{ width: "100%", height: "100%" }} />
        </div>
        <div
          className="career-paths-main mt-5"
          style={{ maxWidth: "100%", overflow: "hidden" }}
        >
          <Container>
            <Row>
              <h1 style={{ maxWidth: "100%", overflow: "hidden" }}>
                Career Paths:
              </h1>
              <Col md={6}>
                <div className="career-path-div">
                  <div className="career-paths mt-4">
                    <ul>
                      <li>Commercial interior designer</li>
                      <li>Residential interior designer</li>
                      <li>Institutional interior designer</li>
                      <li>Exhibition designer furniture designer</li>
                    </ul>
                  </div>
                </div>
              </Col>

              {/* second column starts here */}

              <Col md={6}>
                <div className="career-path-div">
                  <div className="career-paths mt-4">
                    <ul>
                      <li>Design consultant</li>
                      <li>Lighting designer</li>
                      <li>Cad designer</li>
                      <li>Freelancer designer</li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="btns"
          id="contact-sticky-button"
          style={{ maxWidth: "100%", overflow: "hidden" }}
          onClick={(e) => handleContact(e)}
        >
          <span
            style={{ cursor: "pointer", color: "#fff", padding: "5px" }}
            className="pum-trigger-admission"
          >
            Contact Us
          </span>
        </div>
        <div
          className="btns"
          id="admission-sticky-button"
          style={{ overflow: "hidden", maxWidth: "100%", cursor: "pointer" }}
          onClick={handleAdmissionForm}
        >
          <span className="pum-trigger-admission">Admission Form</span>
        </div>
        <div
          className="btns"
          id="getfreecoun-sticky-button"
          style={{ overflow: "hidden", maxWidth: "100%" }}
          onClick={(e) => handleContact(e)}
        >
          <span
            className="pum-trigger-getfreecoun"
            style={{ cursor: "pointer" }}
          >
            Get Free Career Counseling
          </span>
        </div>
        <div className="essential-duties">
          <Container>
            <Row>
              <div
                className="essential-text mt-5"
                style={{ maxWidth: "100%", overflow: "hidden" }}
              >
                <h2
                  className="text-center"
                  style={{ maxWidth: "100%", overflow: "hidden" }}
                >
                  Essential Duties and Responsibilities of a  Interior Designer
                </h2>
                <div className="d-flex f-design">
                  <Col md={6}>
                    <div className="essential-text-data mt-4 mx-5">
                      <ul style={{ color: "#333333", fontSize: "18px" }}>
                        <li>
                          Work with interior design firm to develop design
                          solutions for clients
                        </li>
                        <li>
                          Research concepts and materials to support design team
                          on technical issues influencing concept development
                        </li>
                        <li>
                          Conduct on-site observations and provide
                          recommendations to help streamline ongoing design
                          projects
                        </li>
                        <li>Prepare and participate in client presentations</li>
                      </ul>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div
                      className="essential-text-data mt-4 mx-5"
                      style={{ color: "#333333", fontSize: "18px" }}
                    >
                      <ul>
                        <li>
                          Select furniture, materials, decor and finishes while
                          keeping in mind the customers budget and the approved
                          design
                        </li>
                        <li>
                          Design samples for clients needs and get approvals
                        </li>
                        <li>Design sites from commercial to residential</li>
                        <li>
                          Interior Designer will be part of a collaborative team
                          of Engineers, Architects and Design Assistants
                        </li>
                      </ul>
                    </div>
                  </Col>
                </div>
              </div>
            </Row>
          </Container>
        </div>
        <div>
          <Row>
            <Container>
              <section className="contact-us-form mt-5 mb-5">
                <Container>
                <div className="booking-form">
            <Form  action="https://formspree.io/f/mvoevrna"
  method="POST">
              <div className="form-group">
                <div className="form-checkbox">
                  <label>
                    <span className="text-center">Book your courses..</span>
                  </label>
                </div>
                <Row className="mt-2">
                  <Col md={6}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        Name
                      </span>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        E-MAIL ADDRESS
                      </span>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={6}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        PHONE NUMBER
                      </span>
                      <input
                        className="form-control"
                        type="text"
                        name="contact number"
                        placeholder="Your contact number"
                        required
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        SELECT COURSE
                      </span>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        name="courses"
                        required
                      >
                       <option>Open this select menu</option>
                        <option value="Fashion Design">Fashion Design</option>
                        <option value="Interior Design">Interior Design</option>
                        <option value="Graphic Design">Graphic Design</option>
                        <option value="Animation Design">Animation Design</option>
                        <option value="Luxury Management">Luxury Management</option>
                        <option value="Jewellary Design">Jewellary Design</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-btn">
                      <button className="submit-btn">Book Now</button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
                </Container>
              </section>
            </Container>
          </Row>
        </div>
        <div className="fashion-designing-courses mt-5">
          <Container>
            <Row>
              <div
                className="fashion-designing-text"
                style={{
                  maxWidth: "100%",
                  overflow: "hidden",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                }}
              >
                <h1 style={{ maxWidth: "100%", overflow: "hidden" }}>
                  Interior Designing Courses in Meerut : UG / PG Courses
                </h1>
                <div className="fas-sub-heading mt-5">
                  <h3>
                    UG: Bachelors Programmes – 4 Years Specialization Course
                    (Eligibility : 10+2)
                  </h3>
                  <div
                    className="fashion-design-tabs"
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => setKey(k)}
                      className="mb-3 mt-4"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    >
                      <Tab
                        eventKey="Year One"
                        title="Year One"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">Semester – I </th>
                              <th className="py-3 px-4">Semester – II</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Basics of drafting
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Application of drafting
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Basics of graphics
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Application of colors
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction of Interior design
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Principle of interior designing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Introduction to furniture history
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                History of furniture
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Basics of furniture designing
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Glossary of furniture
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Market survey and building construction – part 1
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Market survey & Building construction – Part 2
                              </td>
                            </tr>

                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Materials
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Grooming & communication skills
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              ></td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                AutoCAD
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Two" title="Year Two">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4"> Semester – III </th>
                              <th className="py-3 px-4">Semester – IV</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Building technology
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Building services & estimation
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Life space planning & interior designing
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Urban space planning
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Design graphics & display
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Elements of interior designing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                History of architecture
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Theory of architecture
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Furniture & furnishing
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                Significance of furniture
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Portfolio development
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Project work
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Kitchen & bathroom design
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Market dynamics
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Google sketchUp
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Photoshop
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              ></td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Retail store design
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              ></td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Landscape designing
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Three" title="Year Three">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">Semester – V </th>
                              <th className="py-3 px-4">Semester – VI</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Interior & exterior treatments
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Exterior & interior treatments
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Furniture, furnishing & fittings
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Upholstery furniture & furnishings
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Art & graphics in interior designing
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Application art & graphics in interior designing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Entrepreneurship development
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                CAD (Commercial)
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Computer Aided design
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Building construction – Part 4
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Building construction – Part 3
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Industrial skill project
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Interior designing projects – part 3
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Revit
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                3DS MAX
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Office design
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Lighting technology
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Management skills
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="Year Four" title="Year Four">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th className="py-3 px-4">Semester – VII </th>
                              <th className="py-3 px-4">Semester – VIII</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Exterior & interior designing
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Exterior & interior designing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                History of furniture
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                History of furniture
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Art & graphics in interior designing
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Art & graphics in interior designing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Building construction - Part 5
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Building construction - Part 5
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Project & portfolio
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Project & portfolio
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Furniture & furnishing
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Furniture & furnishing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Exhibition design
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Set designing
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Business English
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Visual merchandising
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-3 px-4 "
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Corel draw
                              </td>
                              <td
                                className="py-3 px-4"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "18px",
                                }}
                              >
                                Thesis project
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                    </Tabs>

                    {/* second Tab */}

                    <div className="fas-sub-heading mt-5">
                      <h3>
                        UG: Bachelors Programmes – 3 Years Specialization Course
                        (Eligibility : 10+2)
                      </h3>
                      <div
                        className="fashion-design-tabs"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={key1}
                          onSelect={(k) => setKey1(k)}
                          className="mb-3 mt-4"
                          style={{ maxWidth: "100%", overflow: "hidden" }}
                        >
                          <Tab
                            eventKey="Year One"
                            title="Year One"
                            style={{ maxWidth: "100%", overflow: "hidden" }}
                          >
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th className="py-3 px-4">Semester – I </th>
                                  <th className="py-3 px-4">Semester – II</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Basics of drafting
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Application of drafting
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Basics of graphics
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Application of colors
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Introduction of Interior design
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Principle of interior designing
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Introduction to furniture history
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    History of furniture
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Basics of furniture designing
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Glossary of furniture
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Market survey and building construction –
                                    part 1
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Market survey & Building construction – Part
                                    2
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Materials
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Grooming & communication skills
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  ></td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    AutoCAD
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Tab>
                          <Tab eventKey="Year Two" title="Year Two">
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th className="py-3 px-4">Semester – III </th>
                                  <th className="py-3 px-4">Semester – IV</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Building technology
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Building services & estimation
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Life space planning & interior designing
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Urban space planning
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Design graphics & display
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Elements of interior designing
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    History of architecture
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Theory of architecture
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Furniture & furnishing
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Significance of furniture
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Portfolio development
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Project work
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Kitchen & bathroom design
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Market dynamics
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Google sketchUp
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Photoshop
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  ></td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Retail store design
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  ></td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Landscape designing
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Tab>
                          <Tab eventKey="Year Three" title="Year Three">
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th className="py-3 px-4">Semester – V </th>
                                  <th className="py-3 px-4">Semester – VI</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Interior & exterior treatments
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Exterior & interior treatments
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Furniture, furnishing & fittings
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Upholstery furniture & furnishings
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Art & graphics in interior designing
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Application art & graphics in interior
                                    designing
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Entrepreneurship development
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    CAD (Commercial)
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Computer Aided design
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Building construction – Part 4
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Building construction – Part 3
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Industrial skill project
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Interior designing projects – part 3
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Revit
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    3DS MAX
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Office design
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Lighting technology
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Management skills
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>

                    {/* third tab */}
                    <div className="fas-sub-heading mt-5">
                      <h3>
                        Interior Designing Courses In Ahmedabad : PG Course – 2
                        Years (Eligibility: Graduate)
                      </h3>
                      <div
                        className="fashion-design-tabs"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={key2}
                          onSelect={(k) => setKey2(k)}
                          className="mb-3 mt-4"
                          style={{ maxWidth: "100%", overflow: "hidden" }}
                        >
                          <Tab
                            eventKey="Year One"
                            title="Year One"
                            style={{ maxWidth: "100%", overflow: "hidden" }}
                          >
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th className="py-3 px-4">
                                    FIRST SEMESTER – 20 CREDITS{" "}
                                  </th>
                                  <th className="py-3 px-4">
                                    SECOND SEMESTER – 24 CREDITS
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Basics of Drafting
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Application of Drafting (3BHK)
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Basics of Graphics
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Principle of Interior Designing (Creche)
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Introduction of Interior Designing
                                    (Fundamentals & Studio Apartment)
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Building Construction – 2
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Basics of Furniture Designing
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Grooming & Communication Skills
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Materials & Market Survey
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    AutoCAD
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Building Construction – 1
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Textile for Interiors
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Concept Style Studio
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Communication Technology for Interiors
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Advanced Furniture Design
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Market Visit
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Market Visit
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Site Visit
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Site Visit
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Product Workshop
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Product Workshop
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  ></td>
                                </tr>
                              </tbody>
                            </Table>
                          </Tab>
                          <Tab eventKey="Year Two" title="Year Two">
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th className="py-3 px-4">
                                    THIRD SEMESTER – 20 CREDITS{" "}
                                  </th>
                                  <th className="py-3 px-4">
                                    FOURTH SEMESTER – 24 CREDITS
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Office Design
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Kitchen Design
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Retail Store Design
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Bathroom Design
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Restaurant and Bar Design
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Service System
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Product Workshop
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    History of Architecture – 4
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    History of Architecture – 3
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Furniture Designing and Detailing
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Google Sketch Up
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Market Visit
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Market Visit
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Site Visit
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Site Visit
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Product Workshop
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>

                    {/* fourth tab */}

                    <div className="fas-sub-heading mt-5">
                      <h3>2 Years Advance Course (Eligibility 10+2)</h3>
                      <div
                        className="fashion-design-tabs"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={key3}
                          onSelect={(k) => setKey3(k)}
                          className="mb-3 mt-4"
                          style={{ maxWidth: "100%", overflow: "hidden" }}
                        >
                          <Tab
                            eventKey="Year One"
                            title="Year One"
                            style={{ maxWidth: "100%", overflow: "hidden" }}
                          >
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th className="py-3 px-4">
                                    FIRST SEMESTER – 20 CREDITS{" "}
                                  </th>
                                  <th className="py-3 px-4">
                                    SECOND SEMESTER – 24 CREDITS
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Basics of Drafting
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Application of Drafting (3BHK)
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Basics of Graphics
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Application of Colors
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Introduction of Interior
                                    Designing(Fundamentals & Studio Apartment)
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Principle of Interior Designing (Creche)
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    History of Architecture – 1
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    History of Architecture – 2
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Basics of Furniture Designing
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Glossary of Furniture
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Materials & Market Survey
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Building Construction – 2
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Building Construction – 1
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Grooming & Communication Skills
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Market Visit
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    AutoCAD
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Site Visit
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Market Visit
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Product Workshop
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Site Visit
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  ></td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Product Workshop
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Tab>
                          <Tab eventKey="Year Two" title="Year Two">
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th className="py-3 px-4">
                                    THIRD SEMESTER – 20 CREDITS
                                  </th>
                                  <th className="py-3 px-4">
                                    FOURTH SEMESTER – 24 CREDITS
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Office Design
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Kitchen Design
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Retail Store Design
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Bathroom Design
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Restaurant and Bar Design
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Service System
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Product Workshop
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    History of Architecture – 4
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    History of Architecture – 3
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Furniture Designing and Detailing
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Google Sketch Up
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Market Visit
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Market Visit
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Site Visit
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Site Visit
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Product Workshop
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>

                    {/* fifth tab */}
                    <div className="fas-sub-heading mt-5">
                      <h3>1 Year Diploma In Interior Designing</h3>
                      <div
                        className="fashion-design-tabs"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={key4}
                          onSelect={(k) => setKey4(k)}
                          className="mb-3 mt-4"
                          style={{ maxWidth: "100%", overflow: "hidden" }}
                        >
                          <Tab
                            eventKey="Year One"
                            title="Year One"
                            style={{ maxWidth: "100%", overflow: "hidden" }}
                          >
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th className="py-3 px-4">Semester – I</th>
                                  <th className="py-3 px-4">Semester – II</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Basics of drafting
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Application of drafting
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Basics of graphics
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Application of colours
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Introduction of Interior design
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Principle of interior designing
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Introduction to furniture history
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    History of furniture
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Basics of furniture designing
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Glossary of furniture
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Market survey and building construction –
                                    part 1
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Market survey & Building construction – Part
                                    2
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Materials
                                  </td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Grooming & communication skills
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  ></td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    AutoCAD
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  ></td>
                                  <td
                                    className="py-3 px-4"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {" "}
                                    Decorative interior & exterior treatments
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                    {/* sixth tab */}
                    <div className="fas-sub-heading mt-5">
                      <h3>Certificate Program- 6 Months (Eligibility: 10th)</h3>
                      <div
                        className="fashion-design-tabs"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={key5}
                          onSelect={(k) => setKey5(k)}
                          className="mb-3 mt-4"
                          style={{ maxWidth: "100%", overflow: "hidden" }}
                        >
                          <Tab
                            eventKey="6 Months"
                            title="6 Months"
                            style={{ maxWidth: "100%", overflow: "hidden" }}
                          >
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th className="py-3 px-4">6 Months </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Basics of drafting
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Basics of graphics
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Fundamentals of interior designing
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Introduction to furniture designing
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Basics of furniture designing
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Building construction
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Materials
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    AutoCAD
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Studio apartment
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Creche design
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-3 px-4 "
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "18px",
                                    }}
                                  >
                                    3BHK design
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </div>
      <ContactUsModal
        contactModal={contactModal}
        nameModal={nameModal}
        toggleModal={toggleModal}
      />
    </>
  );
};

export default InteriorDesigning;
