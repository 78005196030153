import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Form from "react-bootstrap/Form";
import "../../App.css";

const ContactUsModal = ({ contactModal, nameModal, toggleModal }) => {
  console.log("props", nameModal);
  const [modal, setModal] = useState(false);
  console.log("modal", modal);
  useEffect(() => {
    setModal(contactModal);
    if (contactModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [contactModal]);
  const toggle = () => {
    setModal(!modal);
    if (toggleModal) {
      toggleModal(!modal); // Notify parent component to update its state
    }
  };
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    course: '',
    campus: '',
    message: ''
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
 
  return (
    <>
     

      <div>
      {/* <Button onClick={toggle}>Open Modal</Button> */}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} className=""></ModalHeader>
        <Form  action="https://formspree.io/f/mvoevrna"
  method="POST">

        <ModalBody>
          <h4
            className="text-center"
            style={{
              maxWidth: "100%",
              overflow: "hidden",
              fontWeight: "normal",
            }}
          >
            {nameModal === "Contact Us"
              ? "Admission Open"
              : nameModal === "ADMISSIONS OPEN FOR UG/PG & DIPLOMA"
              ? "Admission Open"
              : "Get Free Career Counseling"}
          </h4>

            <Form.Group className="mb-3">
              <Form.Control type="text" placeholder="*Name" name="name" value={formData.name} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control type="email" placeholder="*Email" name="email" value={formData.email} onChange={handleChange} required/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control type="text" placeholder="Mobile Number *" name="mobile" value={formData.mobile} onChange={handleChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Select aria-label="Default select example" name="course" value={formData.course} onChange={handleChange}>
                <option>*Select Course</option>
                <option value="Fashion Designing">Fashion Designing</option>
                <option value="Interior Designing">Interior Designing</option>
                <option value="Luxury Designing">Luxury Designing</option>
                <option value="Animation Designing">Animation Designing</option>
                <option value="Graphic Designing">Graphic Designing</option>
              </Form.Select>
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Select aria-label="Default select example" name="campus" value={formData.campus} onChange={handleChange}>
                <option>*Select Campus</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </Form.Group> */}
            <Form.Group className="mb-3">
              <Form.Control as="textarea" rows={3} placeholder="Message" name="message" value={formData.message} onChange={handleChange} required/>
            </Form.Group>
        </ModalBody>
        <ModalFooter>
          <div className="submit">
            <button type="submit" className="button">Submit</button>
          </div>
        </ModalFooter>
        </Form>

      </Modal>
    </div>
    </>
  );
};

export default ContactUsModal;
