import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import "../App.css";
import { Row, Col, Container } from "react-bootstrap";
import placementImg from "../assets/images/placement.png";
import recuterimage from "../assets/images/INSD-client-logos-2.jpg";
import Form from "react-bootstrap/Form";
import wtus1 from "../assets/images/wtu-1.png";
import wtus2 from "../assets/images/wtu-2.png";
import wtus3 from "../assets/images/wtus-3.png";
import wtus4 from "../assets/images/wtus-4.png";
import wtus5 from "../assets/images/wtus-5.png";
import wtus6 from "../assets/images/wtus-6.png";
import wtus7 from "../assets/images/wtu-7.png";
import wtus8 from "../assets/images/wtu-8.png";
import wtus9 from "../assets/images/wtu-9.png";
import wtus10 from "../assets/images/wtu-10.png";
import wtus11 from "../assets/images/wtu-11.png";
import wtus12 from "../assets/images/wtu-12.png";
import awd1 from "../assets/images/awd-1.jpg";
import awd2 from "../assets/images/awd-2.jpg";
import awd3 from "../assets/images/awd-3.jpg";
import awd4 from "../assets/images/awd-4.jpg";
import about1 from "../assets/images/aboutus1.jpg";
import about2 from "../assets/images/about2.jpg";
import about3 from "../assets/images/about3.jpg";
import ContactUsModal from "./modals/ContactUsModal";
import { useNavigate } from "react-router-dom";
import std1 from "../assets/images/std1.png";
import std2 from "../assets/images/std2.jpg";
import YouTube from "react-youtube";
import brochurePdf from '../assets/images/E-brochure INSD Meerut.pdf';
import recuter2 from '../assets/images/rec2.jpg'
import recuter3 from '../assets/images/rec3.jpg';
import Fade from 'react-reveal/Fade';
import { motion } from "framer-motion"

const Main = () => {
  const [show, setShow] = useState();
  const [contactModal, setContactModal] = useState(false);
  const [nameModal, setNameModal] = useState('');
  const navigate = useNavigate();
  const videoId = "mUAuMxugpuY";
  const secondVideoId = 'BUK6LVo7C-I';
  const thirdVideoId = 'P0DX6nYbM5k';
  const fourthVideoId = 'L84ekuXG6lc';
  const fifthVideoId = 'UsSRWgKEHsg';
  const sixthVideoId = 'e6SKozDdk-4';
  const seventhVideoId = 'r9yaPOBrQkQ';
  const eighthVideoId = 'Kd4FHVi7-gE';
  // const ninethVideoId = 'ECWHIzWHG0g';
  const handleShow = () => {
    setShow("none");
  };
  const handleFashionDesign = () => {
    navigate('/fashion-design')

  }
useEffect(() => {
  setTimeout(() => {
    setNameModal('Contact Us')
    setContactModal(true)
}, 3000);
}, [])

  const handleGraphic = () => {
    navigate('/graphic-design')
  }
  const animation = () => {
    navigate('/animation-design')
  }
  const interior = () => {
    navigate('/interior-design')
  }
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  const opts1 = {
    height: "200",
    width: "250",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  const opts2 = {
    height: "200",
    width: "250",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  const opts4 = {
    height: "200",
    width: "350",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  const handleNone = () => {
    setShow("show");
  };
  const handleDownload = () => {
    // Use the imported PDF file
    const pdfUrl = brochurePdf;
    // Set the file name for download
    const fileName = 'E-brochure INSD Meerut.pdf';

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = fileName;

    // Append the anchor element to the body
    document.body.appendChild(link);

    // Trigger a click event to initiate download
    link.click();

    // Remove the anchor element from the body
    document.body.removeChild(link);
  };
  const handleContact = (e) => {
    console.log('ee', e.target.innerText)
    setNameModal(e.target.innerText);
    setContactModal(true);
  }
  const handleCourse = () => {
    setNameModal('Contact Us');
    setContactModal(true);
  }
  const toggleModal = (isOpen) => {
    setContactModal(isOpen);
  };

  const handleAdmissionForm = () => {
    navigate('/admission-form');
  }

  return (
    <>
      <section
        className="insd"
        onMouseEnter={handleShow}
        onMouseLeave={handleNone}
      >
        <Row className="noPadding">
          {/* <h2 className={`${show} h2hide`}>6 Streams, 1 College</h2> */}
          <Col
            md={2}
            style={{ padding: "0", borderRight: "1px solid transparent" }}
          >
            <a  className="insd-link" onClick={handleFashionDesign}>
              <div className="insd-img fashion overlay insd-img-2">
                {/* <span style={{ fontSize: "18px" }}>FASHION DESIGN</span> */}
              </div>
              <div className="img-box">
                <div className="inner-text px-4 py-3">
                  <p>FASHION DESIGN</p>
                  <span><span style={{fontWeight:'bold'}}>Eligibility :</span>  10+2</span>
                  <div className="mt-1">
                  <span><span style={{fontWeight:'bold'}}>Duration :</span> 1 Year</span>
                  </div>
                  
                </div>
              </div>
            </a>
          </Col>
         
          <Col
            md={2}
            style={{ padding: "0", borderRight: "1px solid transparent" }}
          >
            <a  className="insd-link" onClick={interior}>
              <div className="insd-img interior overlay insd-img-4">
                {/* <span style={{ fontSize: "18px" }}>INTERIOR DESIGN</span> */}
              </div>
              <div className="img-box">
                <div className="inner-text p-3">
                  <p>INTERIOR DESIGN</p>
                  <span><span style={{fontWeight:'bold'}}>Eligibility :</span>  10+2</span>
                  <div className="mt-1">
                  <span><span style={{fontWeight:'bold'}}>Duration :</span> 2 - 3 Years</span>
                  </div>
                  
                </div>
              </div>
            </a>
          </Col>
          <Col
            md={2}
            style={{ padding: "0", borderRight: "1px solid transparent" }}
          >
            <a  className="insd-link" onClick={handleGraphic}>
              <div className="insd-img graphic overlay insd-img-3">
                {/* <span style={{ fontSize: "18px" }}>GRAPHIC DESIGN</span> */}
              </div>
              <div className="img-box">
                <div className="inner-text p-3">
                  <p>GRAPHIC DESIGN</p>
                  <span><span style={{fontWeight:'bold'}}>Eligibility :</span>  10+2</span>
                  <div className="mt-1">
                  <span><span style={{fontWeight:'bold'}}>Duration :</span> 2 - 3 Years</span>
                  </div>
                  
                </div>
              </div>
            </a>
          </Col>
          <Col
            md={2}
            style={{ padding: "0", borderRight: "1px solid transparent" }}
          >
            <a  className="insd-link" onClick={animation}>
              <div className="insd-img animation overlay insd-img-1">
                {/* <span style={{ fontSize: "18px" }}>ANIMATION</span> */}
              </div>
              <div className="img-box">
                <div className="inner-text p-3">
                  <p>ANIMATION DESIGN</p>
                  <span><span style={{fontWeight:'bold'}}>Eligibility :</span>  10+2</span>
                  <div className="mt-1">
                  <span><span style={{fontWeight:'bold'}}>Duration :</span> 2 - 3 Years</span>
                  </div>
                  
                </div>
              </div>
            </a>
          </Col>
          <Col
            md={2}
            style={{ padding: "0", borderRight: "1px solid transparent" }}
          >
            <a className="insd-link">
              <div className="insd-img luxury overlay insd-img-5">
                {/* <span style={{ fontSize: "18px" }}>LUXURY MANAGEMENT</span> */}
              </div>
              <div className="img-box">
                <div className="inner-text p-3">
                  <p>LUXURY MANAGEMENT</p>
                  <span><span style={{fontWeight:'bold'}}>Eligibility :</span>  10+2</span>
                  <div className="mt-1">
                  <span><span style={{fontWeight:'bold'}}>Duration :</span> 2 - 3 Years</span>
                  </div>
                  
                </div>
              </div>
            </a>
          </Col>
          <Col
            md={2}
            style={{ padding: "0", borderRight: "1px solid transparent" }}
          >
            <a  className="insd-link">
              <div className="insd-img jewellery overlay insd-img-6">
                {/* <span style={{ fontSize: "18px" }}>JEWELLERY DESIGN</span> */}
              </div>
              <div className="img-box">
                <div className="inner-text p-3">
                  <p>JEWELLERY DESIGN</p>
                  <span><span style={{fontWeight:'bold'}}>Eligibility :</span>  10+2</span>
                  <div className="mt-1">
                  <span><span style={{fontWeight:'bold'}}>Duration :</span> 2 - 3 Years</span>
                  </div>
                  
                </div>
              </div>
            </a>
          </Col>
         
        
        </Row>
      </section>
      <div id="ds-sticky-button-placement">
        <a href="#">
          <img src={placementImg} alt=""  className="pct-img"/>
        </a>
      </div>
      <div
        className="btns"
        id="contact-sticky-button"
        style={{ maxWidth: "100%", overflow: "hidden" }}
        onClick={(e)=>handleContact(e)}
      >
       
       
         <span style={{cursor:'pointer', color:'#fff', padding:'5px'}} className="pum-trigger-admission">
         Contact Us
        </span>
      </div>
      <div
        className="btns"
        id="admission-sticky-button"
        style={{ overflow: "hidden", maxWidth: "100%", cursor:'pointer' }}
        onClick={handleAdmissionForm}
      >
        <span className="pum-trigger-admission">
          Admission Form
        </span>
      </div>
      <div
        className="btns"
        id="getfreecoun-sticky-button"
        style={{ overflow: "hidden", maxWidth: "100%" }}
        onClick={(e)=>handleContact(e)}
      >
        <span className="pum-trigger-getfreecoun" style={{cursor:'pointer'}}>
          Get Free Career Counseling
        </span>
      </div>
      <section className="achieve-section">
        <Row style={{ paddingTop: "15px" }}>
          <Col md={3} style={{ borderRight: "1px solid #fff" }}>
            <div className="vc-column-inner">
              <div className="seven-years">
                <div className="data-text">
                  <div>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "22px",
                        fontWeight: 700,
                      }}
                    >
                      25 years of experience
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          
          <Col md={2} style={{ borderRight: "1px solid #fff" }}>
            <div className="vc-column-international">
              <div className="seven-years">
                <div className="data-text">
                  <div>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "28px",
                      }}
                    >
                      7
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "20px",
                        fontWeight: 500,
                      }}
                    >
                      INTERNATIONAL CAMPUSES
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={2} style={{ borderRight: "1px solid #fff" }}>
            <div className="vc-column-campuses">
              <div className="seven-years">
                <div className="data-text">
                  <div>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "28px",
                      }}
                    >
                      50+
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "20px",
                        fontWeight: 500,
                      }}
                    >
                      NATIONAL <br /> CAMPUSES
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={2} style={{ borderRight: "1px solid #fff" }}>
            <div className="vc-column-brand">
              <div className="seven-years">
                <div className="data-text">
                  <div>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "28px",
                      }}
                    >
                      100+
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "20px",
                        fontWeight: 500,
                      }}
                    >
                      BRAND <br /> PARTNERS
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={3} style={{ borderRight: "1px solid #fff" }}>
            <div className="vc-column-winner">
              <div className="seven-years">
                <div className="data-text">
                  <div>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "22px",
                        fontWeight: 700,
                      }}
                    >
                      Winner of the skill catalyst of the year 23
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <section>
     

        <div className="recruters-section pt-5">
          <div className="container">
            <h2
              style={{
                maxWidth: "100%",
                overflow: "hidden",
                fontSize: "55px",
                fontWeight: "bold",
                fontFamily: "sans-serif",
              }}
            >
              Our Recruiters
            </h2>
            <div
              className="scrolling-text-container"
              style={{ overflow: "hidden" }}
            >
            <div className="scrolling-text">
  <img src={recuterimage} alt="Image 1" className="scrolling-image" />
  <img src={recuter2} alt="Image 2" className="scrolling-image" />
  <img src={recuter3} alt="Image 2" className="scrolling-image" />

</div>


            </div>
          </div>
        </div>
      </section>
      <section className="contact-us-form mt-5 mb-5">
        <Container>
          <Fade>
          <div className="booking-form">
            <Form  action="https://formspree.io/f/mvoevrna"
  method="POST">
              <div className="form-group">
                <div className="form-checkbox">
                  <label>
                    <span className="text-center">Book your courses..</span>
                  </label>
                </div>
                <Row className="mt-2">
                  <Col md={6}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        Name
                      </span>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        E-MAIL ADDRESS
                      </span>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={6}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        PHONE NUMBER
                      </span>
                      <input
                        className="form-control"
                        type="text"
                        name="contact number"
                        placeholder="Your contact number"
                        required
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-group">
                      <span
                        className="form-label"
                        style={{ maxWidth: "100%", overflow: "hidden" }}
                      >
                        SELECT COURSE
                      </span>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        name="course"
                        required
                      >
                        <option>Open this select menu</option>
                        <option value="Fashion Design">Fashion Design</option>
                        <option value="Interior Design">Interior Design</option>
                        <option value="Graphic Design">Graphic Design</option>
                        <option value="Animation Design">Animation Design</option>
                        <option value="Luxury Management">Luxury Management</option>
                        <option value="Jewellary Design">Jewellary Design</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-btn">
                      <button className="submit-btn">Book Now</button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
          </Fade>
        </Container>
      </section>
      {/* About us INSD Section starts here */}
      <section className="about-us-section">
        <Container fluid>
          <Row>
            <Container style={{ maxWidth: "100%", overFlow: "hidden" }}>
              <div
                className="about-us-text text-center"
                style={{ maxWidth: "100%" }}
              >
                <Fade>
                <h2 style={{ maxWidth: "100%" }}>
                  Learn From Leaders – One Of The Best Designing Institute In
                  Meerut
                </h2>
                </Fade>
                <Fade>
                <div style={{ padding: "0px 105px" }} className="mt-4 about-insd">
                  <p style={{ padding: "0px 85px", fontSize: "19px" }} className='about-insd-2'>
                    <span style={{ fontWeight: "bold" }}>INSD</span> Meerut lives
                    true to the fundamental meaning and purpose of design; we
                    nurture aspiring designers, help them bring their ideas to
                    life which help them develop wings to sore high with
                    ambition and turn their dreams to reality.
                  </p>
                </div>
                </Fade>
                <div className=" mt-5">
                  <div className="wqb_wrapper">
                    <Row>
                      {/* <div className="left-about-border"></div> */}
                     
                      <Col md={6}>
                      <Fade>
                        <div className="first-about-text">
                          <div
                            className="about-text"
                            style={{
                              maxWidth: "100%",
                              overFlow: "hidden",
                              display: "flex",
                              justifyContent: "start",
                              color: "#A52A2A",
                              fontWeight: "bold",
                              fontFamily: "Barlow",
                              marginTop: "20px",
                            }}
                          >
                            <h2
                              style={{
                                fontSize: "29px",
                                fontWeight: "500",
                                maxWidth: "100%",
                                overflow: "hidden",
                              }}
                            >
                              About INSD
                            </h2>
                          </div>
                          <div
                            className="list-about-text"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: "15px",
                              fontSize: "22px",
                            }}
                          >
                            <ul
                              style={{
                                listStyleType: "circle",
                                textAlign: "justify",
                                paddingInlineStart: "0px",
                                fontSize: "20px",
                                fontFamily: "Barlow",
                              }}
                            >
                              <li
                                style={{
                                  listStyleType: "circle",
                                  lineHeight: "30px",
                                  textAlign: "justify",
                                  fontSize: "20px",
                                }}
                              >
                                At INSD, Meerut we strongly believe in
                                providing quality education by setting high
                                academic standards and providing hands-on
                                learning experiences to its students.
                              </li>
                              <li
                                style={{
                                  listStyleType: "circle",
                                  marginTop: "20px",
                                  lineHeight: "30px",
                                  textAlign: "justify",
                                }}
                              >
                                The wide variety of entrepreneurial programs
                                offered to students makes sure the current needs
                                of the industry are met. We encourage start-ups
                                and guide individuals to successful businesses
                                during their course of study at the Best
                                Designing Institute in Meerut.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Fade>

                        <div
                          className="read-more"
                          style={{ maxWidth: "100%", overflow: "hidden" }}
                        >
                          <button className="read-more-btn">Read More</button>
                        </div>
                      </Col>
                      <Col md={6} style={{ paddingLeft: "0" }}>
                        <Fade>
                        <div className="about-us-img-1">
                          <div className="about-img">
                            <img src={about1} className="img-fluid" />
                          </div>
                        </div>
                        </Fade>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Container>
          </Row>
          <Row>
            <Col md={6} style={{ paddingRight: "0" }}>
              <Fade>
              <div className="about-us-img-2">
                <div className="about-img2">
                  <img src={about2} className="img-fluid" />
                </div>
              </div>
              </Fade>
            </Col>
            <Col md={6} style={{ paddingLeft: "0" }}>
              <Fade>
              <div className="vision-about-text">
                <div
                  className="about-text"
                  style={{
                    maxWidth: "100%",
                    overFlow: "hidden",
                    display: "flex",
                    justifyContent: "start",
                    color: "#A52A2A",
                    fontWeight: "bold",
                    fontFamily: "Barlow",
                    marginTop: "20px",
                  }}
                >
                  <h2
                    style={{
                      fontSize: "29px",
                      fontWeight: "500",
                      maxWidth: "100%",
                      overflow: "hidden",
                      paddingLeft: "25px",
                    }}
                  >
                    Vision
                  </h2>
                </div>
                <div
                  className="list-about-text"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "15px",
                    fontSize: "22px",
                    paddingLeft: "25px",
                  }}
                >
                  <ul
                    style={{
                      listStyleType: "circle",
                      textAlign: "justify",
                      paddingInlineStart: "0px",
                      fontSize: "20px",
                      fontFamily: "Barlow",
                    }}
                  >
                    <li
                      style={{
                        listStyleType: "circle",
                        lineHeight: "30px",
                        textAlign: "justify",
                        fontSize: "20px",
                      }}
                    >
                      To create design professionals with an excellent skill set
                      fulfilling the requirements of the design industry
                    </li>
                    <li
                      style={{
                        listStyleType: "circle",
                        marginTop: "20px",
                        lineHeight: "30px",
                        textAlign: "justify",
                      }}
                    >
                      Updating design education by recognizing the changes in
                      economic and business patterns nationally and globally
                    </li>
                    <li
                      style={{
                        listStyleType: "circle",
                        marginTop: "20px",
                        lineHeight: "30px",
                        textAlign: "justify",
                      }}
                    >
                      All-round development of the students
                    </li>
                    <li
                      style={{
                        listStyleType: "circle",
                        marginTop: "20px",
                        lineHeight: "30px",
                        textAlign: "justify",
                      }}
                    >
                      Providing exposure to emerging trends and technology
                    </li>
                  </ul>
                </div>
              </div>
              </Fade>
            </Col>
          </Row>

          <Row>
            {/* <div className="left-about-border"></div> */}
            <Col md={6}>
              <Fade>
              <div className="first-about-text mt-5">
                <div
                  className="about-text"
                  style={{
                    maxWidth: "100%",
                    overFlow: "hidden",
                    display: "flex",
                    justifyContent: "start",
                    color: "#A52A2A",
                    fontWeight: "bold",
                    fontFamily: "Barlow",
                    marginTop: "20px",
                  }}
                >
                  <h2
                    style={{
                      fontSize: "29px",
                      fontWeight: "500",
                      maxWidth: "100%",
                      overflow: "hidden",
                    }}
                  >
                    Mission
                  </h2>
                </div>
                <div
                  className="list-about-text"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "15px",
                    fontSize: "22px",
                  }}
                >
                  <ul
                    style={{
                      listStyleType: "circle",
                      textAlign: "justify",
                      paddingInlineStart: "0px",
                      fontSize: "20px",
                      fontFamily: "Barlow",
                    }}
                  >
                    <li
                      style={{
                        listStyleType: "circle",
                        lineHeight: "30px",
                        textAlign: "justify",
                        fontSize: "20px",
                      }}
                    >
                      Becoming a repository of design knowledge, experience and
                      information on products, systems, materials, design and
                      production processes related to traditional as well as
                      modern technologies.
                    </li>
                    <li
                      style={{
                        listStyleType: "circle",
                        marginTop: "20px",
                        lineHeight: "30px",
                        textAlign: "justify",
                      }}
                    >
                      To place designers in major categories which benchmark the
                      standards of design education and practice, and encourage
                      them to think ‘global and act local’ supporting the local
                      craftsmanship and sources of India.
                    </li>
                    <li
                      style={{
                        listStyleType: "circle",
                        marginTop: "20px",
                        lineHeight: "30px",
                        textAlign: "justify",
                      }}
                    >
                      To make quality and value-based learning in the design industry of utmost importance.
                    </li>
                  </ul>
                </div>
              </div>
              </Fade>
            </Col>
            <Col md={6} style={{ paddingLeft: "0" }}>
              <Fade>
              <div className="about-us-img-1">
                <div className="about-img">
                  <img src={about3} className="img-fluid" />
                </div>
              </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </section>
      {/* About us INSD Section ends here */}

      {/* What makes us unique starts here */}
      <section className="one-section wqb_row us_custom_ba8e029c bg-img height_custom with_img with-shape">
          <Fade>
        <Container fluid>
          <div className="one-section-img">
            <Container>
              <div className="text-center" style={{ overflow: "hidden", paddingTop:'60px' }}>
                <h3 style={{ maxWidth: "100%", overflow: "hidden" }}>
                  What Makes Us Unique?
                </h3>
              </div>
              <div className="what-make-data mt-5">
                <Row>
                  <Col md={3}>
                    <div className="w-icon-box">
                      <img src={wtus1} style={{ width: "80", height: 80 }} />
                    </div>
                    <div className="w-iconbox-meta pt-2">
                      <h3 class="w-iconbox-title" style={{ fontSize: "20px" }}>
                        U.G.C Recognised Degree <br /> and Diploma courses
                      </h3>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="w-icon-box">
                      <img src={wtus2} style={{ width: "80", height: 80 }} />
                    </div>
                    <div className="w-iconbox-meta pt-2">
                      <h3 class="w-iconbox-title" style={{ fontSize: "20px" }}>
                        Curriculum Created by <br /> Industry Experts
                      </h3>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="w-icon-box">
                      <img src={wtus3} style={{ width: "80", height: 80 }} />
                    </div>
                    <div className="w-iconbox-meta pt-2">
                      <h3 class="w-iconbox-title" style={{ fontSize: "20px" }}>
                        Well Qualified trainers
                      </h3>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="w-icon-box">
                      <img src={wtus4} style={{ width: "80", height: 80 }} />
                    </div>
                    <div className="w-iconbox-meta pt-2">
                      <h3 class="w-iconbox-title" style={{ fontSize: "20px" }}>
                        Industry Specific Internship
                      </h3>
                    </div>
                  </Col>
                </Row>
                {/* Row 2 here */}
                <Row className="mt-5">
                  <Col md={3}>
                    <div className="w-icon-box">
                      <img src={wtus5} style={{ width: "80", height: 80 }} />
                    </div>
                    <div className="w-iconbox-meta pt-2">
                      <h3 class="w-iconbox-title" style={{ fontSize: "20px" }}>
                        Live Project Training
                      </h3>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="w-icon-box">
                      <img src={wtus6} style={{ width: "80", height: 80 }} />
                    </div>
                    <div className="w-iconbox-meta pt-2">
                      <h3 class="w-iconbox-title" style={{ fontSize: "20px" }}>
                        Flexible Timings
                      </h3>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="w-icon-box">
                      <img src={wtus7} style={{ width: "80", height: 80 }} />
                    </div>
                    <div className="w-iconbox-meta pt-2">
                      <h3 class="w-iconbox-title" style={{ fontSize: "20px" }}>
                        Personal Councelling
                      </h3>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="w-icon-box">
                      <img src={wtus8} style={{ width: "80", height: 80 }} />
                    </div>
                    <div className="w-iconbox-meta pt-2">
                      <h3 class="w-iconbox-title" style={{ fontSize: "20px" }}>
                        Placement Assistance
                      </h3>
                    </div>
                  </Col>
                </Row>
                {/* third Row */}
                <Row className="mt-5">
                  <Col md={3}>
                    <div className="w-icon-box">
                      <img src={wtus9} style={{ width: "80", height: 80 }} />
                    </div>
                    <div className="w-iconbox-meta pt-2">
                      <h3 class="w-iconbox-title" style={{ fontSize: "20px" }}>
                        Flexible Payment System
                      </h3>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="w-icon-box">
                      <img src={wtus10} style={{ width: "80", height: 80 }} />
                    </div>
                    <div className="w-iconbox-meta pt-2">
                      <h3 class="w-iconbox-title" style={{ fontSize: "20px" }}>
                        International Student <br /> Exchange Programme
                      </h3>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="w-icon-box">
                      <img src={wtus11} style={{ width: "80", height: 80 }} />
                    </div>
                    <div className="w-iconbox-meta pt-2">
                      <h3 class="w-iconbox-title" style={{ fontSize: "20px" }}>
                        Well Equipped <br /> Infrastructure
                      </h3>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="w-icon-box">
                      <img src={wtus12} style={{ width: "80", height: 80 }} />
                    </div>
                    <div className="w-iconbox-meta pt-2">
                      <h3 class="w-iconbox-title" style={{ fontSize: "20px" }}>
                        WI-Fi Enabled Campus
                      </h3>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <div className="wht-make-btn text-center mt-5 mb-5">
            <button className="wht-btn">Get Free Consultation</button>
          </div>
        </Container>
        </Fade>
      </section>

      {/* what is your design interest section starts here */}
        <section className="design-interest mt-3 mb-5 p-5">
          <div>
            <Container>
                <h3 className="text-center" style={{ maxWidth: "100%", overflow: "hidden" }}>What Is Your Design Interest?</h3>
              <Row className="mt-5">
                <Col md={4}>
                  <Fade>
                  <div className="us_custom_4ce"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                      <div className="w-banner-image"  style={{ maxWidth: "100%", overflow: "hidden", cursor:'pointer' }}>
                        <div className="banner-title"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                          <h2  style={{ maxWidth: "100%", overflow: "hidden", fontSize:'25px' }}>Fashion Design</h2>
                        </div>
                      </div>
                  </div>
                  </Fade>
                </Col>
                <Col md={4}>
                  <Fade>
                  <div className="us_custom_4ce"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                      <div className="w-banner-image-1"  style={{ maxWidth: "100%", overflow: "hidden", cursor:'pointer' }}>
                        <div className="banner-title"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                          <h2  style={{ maxWidth: "100%", overflow: "hidden", fontSize:'25px' }}>Interior Design</h2>
                        </div>
                      </div>
                  </div>
                  </Fade>
                </Col>
                <Col md={4}>
                  <Fade>
                  <div className="us_custom_4ce"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                      <div className="w-banner-image-2"  style={{ maxWidth: "100%", overflow: "hidden", cursor:'pointer' }}>
                        <div className="banner-title"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                          <h2  style={{ maxWidth: "100%", overflow: "hidden", fontSize:'25px' }}>Jewellary Design</h2>
                        </div>
                      </div>
                  </div>
                  </Fade>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col md={4}>
                  <Fade>
                  <div className="us_custom_4ce"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                      <div className="w-banner-image-3"  style={{ maxWidth: "100%", overflow: "hidden", cursor:'pointer' }}>
                        <div className="banner-title"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                          <h2  style={{ maxWidth: "100%", overflow: "hidden", fontSize:'25px' }}>Animation Design</h2>
                        </div>
                      </div>
                  </div>
                  </Fade>
                </Col>
                <Col md={4}>
                  <Fade>
                  <div className="us_custom_4ce"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                      <div className="w-banner-image-4"  style={{ maxWidth: "100%", overflow: "hidden", cursor:'pointer' }}>
                        <div className="banner-title"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                          <h2  style={{ maxWidth: "100%", overflow: "hidden", fontSize:'25px' }}>Textile Design</h2>
                        </div>
                      </div>
                  </div>
                  </Fade>
                </Col>
                <Col md={4}>
                  <Fade>
                  <div className="us_custom_4ce"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                      <div className="w-banner-image-5"  style={{ maxWidth: "100%", overflow: "hidden", cursor:'pointer' }}>
                        <div className="banner-title"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                          <h2  style={{ maxWidth: "100%", overflow: "hidden", fontSize:'25px' }}>Graphics Design</h2>
                        </div>
                      </div>
                  </div>
                  </Fade>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col md={4}>
                  <Fade>
                  <div className="us_custom_4ce"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                      <div className="w-banner-image-6"  style={{ maxWidth: "100%", overflow: "hidden", cursor:'pointer' }}>
                        <div className="banner-title"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                          <h2  style={{ maxWidth: "100%", overflow: "hidden", fontSize:'25px' }}>Fashion Styling</h2>
                        </div>
                      </div>
                  </div>
                  </Fade>
                </Col>
                <Col md={4}>
                  <Fade>
                  <div className="us_custom_4ce"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                      <div className="w-banner-image-7"  style={{ maxWidth: "100%", overflow: "hidden", cursor:'pointer' }}>
                        <div className="banner-title"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                          <h2  style={{ maxWidth: "100%", overflow: "hidden", fontSize:'25px' }}>Luxury Brand</h2>
                        </div>
                      </div>
                  </div>
                  </Fade>
                </Col>
                <Col md={4}>
                  <Fade>
                  <div className="us_custom_4ce"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                      <div className="w-banner-image-8"  style={{ maxWidth: "100%", overflow: "hidden", cursor:'pointer' }}>
                        <div className="banner-title"  style={{ maxWidth: "100%", overflow: "hidden" }}>
                          <h2  style={{ maxWidth: "100%", overflow: "hidden", fontSize:'25px' }}>UI / UX Design</h2>
                        </div>
                      </div>
                  </div>
                  </Fade>
                </Col>
               
              </Row>
              <div className="text-center mt-5">
                  <button className="select_course_btn" onClick={ handleCourse}>Select Your Courses</button>
                </div>
            </Container>

          </div>
        </section>
      {/* what is your design interest section ends here */}
      
      <section className="mt-1 " style={{ backgroundColor: "#262626" }}>
        <Fade>
        <Container fluid>
          <div className="know-more mb-5">
            <div className="know-more-text text-center">
              <h3
                style={{ color: "#fff", fontWeight: "bold", fontSize: "35px", maxWidth: "100%", overflow: "hidden" }}
              >
                Know More About <span className="span">Desired Course</span>
              </h3>
            </div>
            <div className="broc-btn text-center">
              <button className="down-btn" onClick={handleDownload}>Download brochure</button>
            </div>
          </div>
        </Container>
        </Fade>
      </section>

      {/* Students Testimonials starts here */}
      <Fade>
        <section className="student-testimonials mt-5" style={{ maxWidth: "100%", overflow: "hidden" }}>
          <Container>
            <h3 className="text-center" style={{ maxWidth: "100%", overflow: "hidden" }}>Student Testimonials</h3>
            <Row className="mt-5">
              <Col md={5}>
                <div className="testimonial-text pt-4"  style={{maxWidth:'100%', overFlow:'hidden'}}>
                  <p>
As a INSD Student, I got an opportunity to participate in Meerut times fashion week. I’m very grateful that I got such a good institute and mentor.</p>
                </div>
                <div className="std-text d-flex justify-content-evenly">
                  <div className="std-img">
                      <img src={`${std1}`}  style={{borderRadius:'50%'}}/>
                  </div>
                  <div className="img-text">
                    <span style={{fontWeight:'bold'}}>Mahima Kulkarni</span>
                    <p>Student of Fashion Styling</p>
                  </div>
                </div>
                <div className="sec-text mt-5 pt-5">
                  <p>Being at INSD Meerut  was a wonderful experience. I got to learn a lot to go beyond the blues and to experiment with different ideas and with different concepts With the help of with the help of insd experts teachers we can convert our imagination into reality, we could put our imagination to reality she’s is the most amazing mentor we have.</p>
                </div>
              </Col>
              <Col md={7}>
                <div className="d-flex justify-content-center">
                <YouTube videoId={videoId} opts={opts} />
                </div>
              
              </Col>
            </Row>
            <Row>
              <Col md={5}>
              <div className="std-text d-flex justify-content-evenly">
                  <div className="std-img">
                      <img src={`${std2}`}  style={{borderRadius:'50%'}}/>
                  </div>
                  <div className="img-text">
                    <span style={{fontWeight:'bold'}}>Mast. Vinayak madne</span>
                    <p>Student of Fashion Styling</p>
                  </div>
                </div>
              </Col>
              <Col md={7}>
                <div className="main-you d-flex justify-content-evenly mt-5">
                <div className="you-1">
                <YouTube  videoId={secondVideoId} opts={opts1}/>
                </div>
                <div className="you-2">
                <YouTube  videoId={thirdVideoId} opts={opts2}/>
                </div>
                </div>
              </Col>
            </Row>
            <div className="text-center mt-5 pt-4 mb-5">
              <button className="view-more">View More</button>
            </div>
          </Container>
        </section>
        </Fade>
      {/* Students Testimonials ends here */}

      {/* Video Gallery section starts here */}
      <Fade>
        <section className="video-gallery mt-4">
          <Container>
                      <h3 className="text-center"  style={{ maxWidth: "100%", overflow: "hidden" }}>Video Gallery</h3>
            <Row className="mt-5">
                      <Col md={4}>
                      <div className="you-1">
                <YouTube  videoId={fourthVideoId} opts={opts4}/>
                </div>
                      </Col>
                      <Col md={4}>
                      <div className="you-1">
                <YouTube  videoId={fifthVideoId} opts={opts4}/>
                </div>
                      </Col>
                      <Col md={4}>
                      <div className="you-1">
                <YouTube  videoId={sixthVideoId} opts={opts4}/>
                </div>
                      </Col>
            </Row>

            <Row className="mt-5 text-center">
                      <Col md={6}>
                      <div className="you-1">
                <YouTube  videoId={seventhVideoId} opts={opts4}/>
                </div>
                      </Col>
                      <Col md={6}>
                      <div className="you-1">
                <YouTube  videoId={eighthVideoId} opts={opts4}/>
                </div>
                      </Col>
                      {/* <Col md={4}>
                      <div className="you-1">
                <YouTube  videoId={ninethVideoId} opts={opts4}/>
                </div>
                      </Col> */}
            </Row>
          </Container>
        </section>
        </Fade>
      {/* Video Gallery section ends here */}


      <section className="mt-5">
        <Container>
          <div className="awards-section">
            <div className="awards-text">
              <h2 style={{ fontWeight: "bolder", overflow: "hidden" }}>
                Awards & Recognition
              </h2>
            </div>
            <Row>
              <Col md={6} className="mt-5">
                <Fade>
                <Card>
                  <Card.Img variant="top" src={awd1} className="px-4 py-4" />
                </Card>
                </Fade>
              </Col>
              <Col md={6} className="mt-5">
                <Fade>
                <Card>
                  <Card.Img variant="top" src={awd2} className="px-4 py-4" />
                </Card>
                </Fade>
              </Col>
            </Row>
              <Fade>        
            <Row className="mt-5 d-flex mx-auto">
              <div className="d-flex justify-content-center">
                <Col md={6}>
                  <Row>
                    <Col md={6}>
                      <Card>
                        <Card.Img
                          variant="top"
                          src={awd3}
                          className="px-4 py-4"
                        />
                      </Card>
                    </Col>
                    <Col md={6} className="">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={awd4}
                          className="px-4 py-4"
                        />
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </div>
              <div
                className="view-more-btn text-center mt-5"
                style={{ maxWidth: "100%", overflow: "hidden" }}
              >
                <a href="#" className="us-btn-style_27">
                  View More
                </a>
              </div>
            </Row>
            </Fade>
          </div>
        </Container>
        <section style={{maxWidth:'100%'}}>
        <ContactUsModal  contactModal={contactModal} nameModal={nameModal} toggleModal={toggleModal}/>
      
        </section>
      </section>
    </>
  );
};

export default Main;
